import { useEffect, useState } from "react";
import AuthFields from "./AuthFields";

import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  changePassword,
  getCurrentUser,
  getFirebaseErrorMsg,
} from "../api/firebase";
import { useAuth } from "../context/auth-context";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { updateUserPrivacy } from "../api/auth";
import Button from "@mui/material/Button";

interface userPrivacyProps {}
export default function UserPrivacy(props: userPrivacyProps) {
  const [apiMsg, setApiMsg] = useState<string | null>(null);
  const navigate = useNavigate();
  const authContext = useAuth();
  const [userDetails, setUserDetails] = useState(authContext.userDetails);

  useEffect(() => {
    setUserDetails(authContext.userDetails);
  }, [authContext.userDetails])

  const onSave = async () => {
    if (userDetails) await updateUserPrivacy(userDetails.privacyLeader, userDetails.privacyProfile, userDetails.privacyFilm, userDetails.privacyPosts, userDetails.privacyNetwork)
  }
  return (
    <Container
      component="main"
      // maxWidth="xs"
      sx={{ textAlign: "left", marginLeft: '0px', mt: "10px" }}
    >
      {
        userDetails !== null && <>
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyProfile}
            onChange={e => setUserDetails({ ...userDetails, privacyProfile: e.target.checked })} />}
            label='Hide your profile entirely from others (does not hide posts/playlists)' />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyPosts}
            onChange={e => setUserDetails({ ...userDetails, privacyPosts: e.target.checked })} />}
            label="Hide all posts/playlists from others (overrides post/playlist privacy)" />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyFilm}
            onChange={e => setUserDetails({ ...userDetails, privacyFilm: e.target.checked })} />}
            label='Hide the "Film" section of your profile from others' />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyNetwork}
            onChange={e => setUserDetails({ ...userDetails, privacyNetwork: e.target.checked })} />}
            label='Hide the "Network" section of your profile from others' />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyLeader}
            onChange={e => setUserDetails({ ...userDetails, privacyLeader: e.target.checked })} />}
            label="Hide yourself from the Leaderboard" />
          <Button
            sx={{ marginY: '5px' }}
            color='secondary'
            type="submit"
            variant="contained"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      }
    </Container>
  );
}
