import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { playerUI } from "../api/nba";
import { updatePlaylistDetails } from "../api/playlist";
import { useAuth } from "../context/auth-context";
import { useDataContext } from "../context/nba-data";
import { NotePrivacies } from "../types/constants";
import { playlistDto, teamsDto } from "../types/dto";
import PlayersSelector from "./menu/PlayersSelector";
import TeamSelector from "./menu/TeamSelector";
import ModalBase from "./ModalBase";
import NoteText from "./NoteText";

interface helpModalProps {
  open: boolean;
  setOpen: (newVal: boolean) => void,
  button: ReactJSXElement;
  playlist: playlistDto;
}
export default function EditPlaylistMetaModal(props: helpModalProps) {
  const { playlist } = props;
  const [title, setTitle] = useState(playlist.title);
  const [privacy, setPrivacy] = useState(playlist.privacy);
  const [note, setNote] = useState(playlist.note);

  const data = useDataContext();
  const initialPlayer = data.allPlayers.find(p => p.playerId === playlist.playerId);
  const initialTeam = Object.values(data.teams).find(t => t.teamId === Number(playlist.teamId));
  const initialPlayers = initialPlayer ? [initialPlayer] : [];
  const initialTeams = initialTeam ? [initialTeam] : [];
  const [selectedPlayers, setSelectedPlayers] = useState(initialPlayers as playerUI[]);
  const [selectedTeams, setSelectedTeams] = useState(initialTeams as teamsDto[]);

  const auth = useAuth();
  const onSave = async () => {
    const teamId = selectedTeams.length > 0 ? selectedTeams[0].teamId : null;
    const playerId = selectedPlayers.length > 0 ? selectedPlayers[0].playerId : null;
    await updatePlaylistDetails(playlist.playlistId, note, title, privacy, teamId, playerId);
    await auth.refreshUserPlaylists();
  }



  // const [open, setOpen] = useState(false);
  // const handleClose = () => setOpen(false);
  return (
    // <>

    //   <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //   >
    //     {/* <DialogTitle id="alert-dialog-title">
    //       {"Use Google's location service?"}
    //     </DialogTitle> */}
    //     <DialogContent>
    //       <DialogContentText id="alert-dialog-description">
    //         {props.children}
    //       </DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //       {/* <Button onClick={handleClose}>Disagree</Button> */}
    //       <Button onClick={handleClose} color="secondary" autoFocus>
    //         Close
    //       </Button>
    //     </DialogActions>
    //   </Dialog>
    // </>
    <ModalBase
      open={props.open}
      setOpen={props.setOpen}
      content={
        <>
          <DialogTitle sx={{ paddingTop: '0px' }}>Edit playlist details</DialogTitle>
          <DialogContentText>
            <Grid container rowSpacing={2} textAlign='center'>
              <Grid item xs={12}>
                <TextField
                  required
                  onKeyDown={e => e.stopPropagation()}
                  multiline
                  label={'Title (required)'}
                  value={title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value);
                  }}
                  fullWidth
                  placeholder='Title (required)'
                  color='secondary'
                  size='small'
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <NoteText
                  isPlaylist={true}
                  userLoggedIn={true}
                  userIsAuthor={true}
                  //   userIsAuthor={userIsAuthor}
                  noteVal={note}
                  onChange={setNote} />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  sx={{ marginRight: "10px" }}
                  // disabled={!userLoggedIn}
                  color="secondary"
                  value={privacy}
                  fullWidth
                  size="small"
                  exclusive
                  onChange={(e, newPrivacy) => {
                    if (newPrivacy) {
                      setPrivacy(newPrivacy);
                    }
                  }}>
                  {NotePrivacies.map((np) => (
                    <ToggleButton
                      key={np}
                      value={np}
                    // disabled if it's not public, and you can't private post
                    // disabled={np !== NotePrivacy.Public && !accountAuth.canPrivatePost}
                    >
                      {np}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
              {/* <Typography variant='h6'  paddingLeft='24px' textAlign='left'>
                  Tags
                </Typography> */}

                <TeamSelector
                  selectedTeams={selectedTeams}
                  maxItems={1}
                  label="Tag Team"
                  onChange={setSelectedTeams}
                />
              </Grid>
              <Grid item xs={12}>
                <PlayersSelector
                  showAbbrs={false}
                  players={data.allPlayers}
                  selectedPlayers={selectedPlayers}
                  maxItems={1}
                  label="Tag Player"
                  onChange={setSelectedPlayers}
                />
                <Typography variant='caption'  component='p' paddingLeft='10px' textAlign='left'>
                  Tags help people discover your playlist
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </>

      }
      button={
        props.button
      }
      actions={
        <>
          <Button color="secondary">
            Close
          </Button>
          <Button onClick={onSave} variant='contained' color="secondary">
            Save
          </Button>
        </>
      }
    />
  );
}
