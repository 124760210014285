import { useEffect, useMemo, useRef, useState } from "react";
import ClipFeed from "../components/ClipFeed";
import { noteAndPbpDto, userGameViewsDto } from "../types/dto";
import UserGame from "../components/UserGame";
import { useDataContext } from "../context/nba-data";
import { latestSeason } from "../types/constants";
import SeasonSelector from "../components/menu/SeasonSelector";
import { searchGames } from "../utils/game-utils";
import { useNavigate } from "react-router-dom";
import { getUserGameViews } from "../api/user";
import { useUserContext } from "../context/user-data";
import Grid from "@mui/material/Grid";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { getGameVideoTotals } from "../api/nba";
import TextField from "@mui/material/TextField";
import { useAuth } from "../context/auth-context";
import PlayerList from "../components/PlayerList";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface filmListProps { }

enum SearchType {
  Game = 'Game',
  Player = 'Player',

}
export default function FilmList(props: filmListProps) {
  const [notes, setNotes] = useState([] as noteAndPbpDto[]);
  const [season, setSeason] = useState(latestSeason);
  const [userSearch, setUserSearch] = useState("");
  const [gameViews, setGameViews] = useState({
    totalGameViews: {},
    userGameViews: {},
    userNotes: {},
    userSaved: {},
    globalGameNotes: {},
    globalGameViews: {},
  } as userGameViewsDto);
  const virtuosoRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState(0);

  const [searchType, setSearchType] = useState(SearchType.Game);
  useEffect(() => {
    const element = virtuosoRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const distanceToViewportTop = rect.top + window.scrollY;
      setDistance(distanceToViewportTop);
    }
  }, []);

  const data = useDataContext();
  const auth = useAuth();
  const userId = auth.userId;

  useEffect(() => {
    if (userId) {
      getUserGameViews(userId, season).then((res) => {
        setGameViews(res);
      });
    } else {
      getGameVideoTotals(season).then((res) => {
        setGameViews(res);
      });
    }
  }, [season, userId]);

  const allGames = data.gamesBySeason[season];

  const visibleGames = useMemo(() => {
    console.log(data.gamesBySeason)
    return allGames.filter(
      (g) => userSearch.length < 2 || searchGames(g, data.teams, userSearch),
    );
  }, [userSearch, season]);
  const navigate = useNavigate();

  return (
    <Grid container sx={{ textAlign: "center", margin: 'auto', maxWidth: '1000px' }}>
      <Grid item xs={12} marginBottom='8px'>
        <Box sx={{ marginBottom: 1, borderBottom: 1, borderColor: 'divider', textColor: 'white' }}>
          <Tabs variant='fullWidth' textColor='inherit' value={searchType} onChange={(e, val) => setSearchType(val)} aria-label="basic tabs example">
            <Tab label={SearchType.Game} value={SearchType.Game} />
            <Tab label={SearchType.Player} value={SearchType.Player} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <SeasonSelector selectedSeason={season} onSelectSeason={setSeason} />
      </Grid>
      <Grid item xs={8} paddingLeft='10px'>
        <TextField
          fullWidth
          value={userSearch}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserSearch(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          color="secondary"
          label={searchType}
          size="small"
          placeholder={searchType === SearchType.Game ? "Filter by team, date, score" : "Filter by name or team"}
        />
      </Grid>
      {
        searchType === SearchType.Game ?
          <Grid item xs={12} ref={virtuosoRef} sx={{ marginTop: "10px" }}>
            <Virtuoso
              data={visibleGames}
              style={{
                height: `calc(100vh - ${distance}px)`,
                maxWidth: "500px",
                margin: "auto",
              }}
              itemContent={(i, game) => (
                <div style={{ marginBottom: "15px" }}>
                  <UserGame
                    game={game}
                    showUserStats={userId ? true : false}
                    navigate={navigate}
                    gameViews={gameViews}
                    season={season}
                  />
                </div>
              )}
            />
          </Grid>
          :
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <PlayerList season={season} userSearch={userSearch} userSearchForDisplay={userSearch} />
          </Grid>
      }
    </Grid>
  );

  // <UserGame

  // game={game}
  // navigate={navigate}
  // gameViews={gameViews}
  // season={season} />;
}
