import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const clutchMessage =
  "Clutch time means score is within 5 points and there are 5 minutes or fewer left in the game";
const quarters = ["1Q", "2Q", "3Q", "4Q", "OT", "Clutch"];
interface quarterSelectorProps {
  quarters: string[];
  setQuarters: (quarters: string[]) => void;
}
export default function QuarterSelector(props: quarterSelectorProps) {
  return (
    <>
      <ToggleButtonGroup
        size="small"
        value={props.quarters}
        onChange={(e, quarters) =>
          quarters !== null && props.setQuarters(quarters)
        }
      >
        <Typography lineHeight={"2rem"}>Quarter&nbsp;&nbsp;</Typography>
        {quarters.map((quarter) => {
          const buttonContent =
            quarter !== "Clutch" ? (
              <span>{quarter}</span>
            ) : (
              <Tooltip title={clutchMessage}>
                <span>{quarter}</span>
              </Tooltip>
            );
          return (
            <ToggleButton key={quarter} value={quarter} aria-label="Earliest">
              {buttonContent}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
}
