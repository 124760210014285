import { useEffect, useState } from "react";
import "./App.css";
import MainLayout from "./components/MainLayout";
import UserAccess, { AccessType } from "./pages/UserAccess";

import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import {
  dataContext,
  DataContext,
  DefaultDataContext,
  getAllPlayers,
} from "./context/nba-data";
import { UserContext } from "./context/user-data";

import {
  convertGames,
  convertSTPToPlayers,
  getAllGames,
  getAllLoadData,
  getAllShotTypes,
  getAllTeamsStps,
} from "./api/nba";
import { getAllSkills } from "./api/skills";
import {
  CLIP_PATH,
  CLIP_TIMELINE_PATH,
  CREATE_USER_PATH,
  GAME_VIDEO_PATH,
  FILM_PATH,
  LEADERS_PATH,
  LOGIN_PATH,
  PLAYER_VIDEO_PATH,
  PLAYLIST_PATH,
  PRIVACY_POLICY_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  TERMS_AND_CONDITIONS_PATH,
  USER_PLAYLISTS_PATH,
  USER_PROFILE_PATH,
  USER_SETTINGS_PATH
} from "./utils/url-utils";

import * as React from "react";

import MuiAlert from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { setOnError } from "./api/utils";
import { AuthProvider } from "./context/auth-context";
import Clip from "./pages/Clip";
import ClipTimeline from "./pages/ClipTimeline";
import GameList from "./pages/FilmList";
import GameVideo from "./pages/GameVideo";
import PlayerVideo from "./pages/PlayerVideo";
import Playlist from "./pages/Playlist";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import UserCreate from "./pages/UserCreate";
import UserPlaylists from "./pages/UserPlaylists";
import UserProfile from "./pages/UserProfile";
import UserSettings from "./pages/UserSettings";
import ViewsLeaderboard from "./pages/ViewsLeaderboard";

const defaultDataContext = DefaultDataContext();

const fetchInitialData = async () => {
  const toReturn = {} as dataContext;
  if (process.env.NODE_ENV === "development") {
    const apiCalls = [
      getAllSkills().then((x) => {
        toReturn.skills = x.roleSkills;
        return true;
      }),
      getAllGames().then((x) => {
        toReturn.gamesBySeason = convertGames(x);
        return true;
      }),
      getAllTeamsStps().then((x) => {
        toReturn.seasonTeamPlayers = x.seasonTeamPlayers;
        toReturn.playersBySeason = convertSTPToPlayers(x);
        toReturn.allPlayers = getAllPlayers(toReturn.playersBySeason);
        toReturn.teams = x.teams;
        return true;
      }),
      getAllShotTypes().then((x) => {
        toReturn.shotTypes = x;
        return true;
      }),
    ];
    await Promise.all(apiCalls);
  } else {
    await getAllLoadData().then((x) => {
      toReturn.skills = x.skills.roleSkills;
      toReturn.gamesBySeason = convertGames(x.games);
      toReturn.seasonTeamPlayers = x.seasonTeamPlayers;
      toReturn.playersBySeason = convertSTPToPlayers(x);
      toReturn.allPlayers = getAllPlayers(toReturn.playersBySeason);
      toReturn.teams = x.teams;
      toReturn.shotTypes = x.shotTypes;
      return true;
    });
  }
  return toReturn;
};

function App() {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [dataContext, setDataContext] = useState(defaultDataContext);
  const [dataContextIsLoaded, setDataContextIsLoaded] = useState(false);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") return;
    setOpenAlert(false);
  };
  setOnError((errorText: string) => {
    // TODO make this close existing alert correctly
    setOpenAlert(false);
    setAlertText(errorText);
    setOpenAlert(true);
  });
  useEffect(() => {
    const onLoadApiCalls = async () => {
      const newDataContext = await fetchInitialData();
      setDataContext(newDataContext);
      setDataContextIsLoaded(true);
    };
    onLoadApiCalls();
  }, []);
  return (
    dataContextIsLoaded && (
      <DataContext.Provider value={dataContext}>
        <AuthProvider>
          <UserContext.Provider
            value={{
              openAlert,
              setOpenAlert,
              alertText,
              setAlertText,
            }}
          >
            <BrowserRouter>
              <MainLayout>
                <Snackbar
                  sx={{ fontSize: "20px" }} // does this work?
                  open={openAlert}
                  autoHideDuration={6000}
                  onClose={handleCloseAlert}
                  TransitionComponent={(props: SlideProps) => (
                    <Slide {...props} direction="down" />
                  )}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <MuiAlert
                    onClose={handleCloseAlert}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {alertText}
                  </MuiAlert>
                </Snackbar>
                <Routes>
                  <Route path={FILM_PATH} element={<GameList />}></Route>
                  <Route
                    path={CLIP_TIMELINE_PATH}
                    element={<ClipTimeline />}
                  ></Route>
                  <Route path={CLIP_PATH} element={<Clip />}></Route>
                  <Route path={PLAYLIST_PATH} element={<Playlist />}></Route>
                  <Route
                    path={LOGIN_PATH}
                    element={<UserAccess accessType={AccessType.LOGIN} />}
                  ></Route>
                  <Route
                    path={REGISTER_PATH}
                    element={<UserAccess accessType={AccessType.REGISTER} />}
                  ></Route>
                  <Route
                    path={RESET_PASSWORD_PATH}
                    element={<UserAccess accessType={AccessType.RESET} />}
                  ></Route>
                  <Route path={CREATE_USER_PATH} element={<UserCreate />}></Route>
                  <Route
                    path={LEADERS_PATH}
                    element={<ViewsLeaderboard />}
                  ></Route>
                  <Route path={GAME_VIDEO_PATH} element={<GameVideo />}></Route>
                  <Route path={PLAYER_VIDEO_PATH} element={<PlayerVideo />}></Route>
                  <Route
                    path={USER_PROFILE_PATH}
                    element={<UserProfile />}
                  ></Route>
                  <Route
                    path={USER_SETTINGS_PATH}
                    element={<UserSettings />}
                  ></Route>
                  <Route
                    path={USER_PLAYLISTS_PATH}
                    element={<UserPlaylists />}
                  ></Route>
                  <Route
                    path={TERMS_AND_CONDITIONS_PATH}
                    element={<TermsAndConditions />}
                  ></Route>
                  <Route
                    path={PRIVACY_POLICY_PATH}
                    element={<PrivacyPolicy />}
                  ></Route>
                  {/* <Route path={USER_GAME_PROFILE_PATH} element={<UserGameProfile />}></Route>
              <Route path={USER_PLAYER_PROFILE_PATH} element={<UserPlayerProfile />}></Route> */}
                </Routes>
              </MainLayout>
            </BrowserRouter>
          </UserContext.Provider>
        </AuthProvider>
      </DataContext.Provider>
    )
  );
}

export default App;
