import { useEffect, useState } from "react";
import AuthFields from "../components/AuthFields";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { login, register } from "../api/auth";
import { getCurrentUser, getUserId } from "../api/firebase";
import { useAuth } from "../context/auth-context";
import { User } from "firebase/auth";

interface createUserProps {}
export default function UserCreate(props: createUserProps) {
  const [user, setUser] = useState(null as User | null);
  const auth = useAuth();
  // needs to be getCurrentUser because may not have csId yet
  const [apiMsg, setApiMsg] = useState<string | null>("Loading...");
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser().then(res => {
      setUser(res);
      if (!(res?.uid)) {
        setApiMsg("Please log in first to complete registration");
      } else {
        getUserId().then(userId => {
          if (userId) setApiMsg("You are already registered");
          else setApiMsg("")
        })
      }
    });
  }, []);

  const onSubmit = async (
    username: string,
    password: string,
    verifyPassword: string,
  ) => {
    if (user && user.email) {
      // user registration
      const res = await register(user.email, username, user.uid);
      if (res.status === 409) setApiMsg("The username is taken");
      else if (res.status > 399) setApiMsg("Failed to register");
      else {
        await login(false);
        auth.refreshAuth();
        return navigate("/");
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ textAlign: "center", mt: "10px" }}
    >
      <Typography variant="h5">Finish Registration</Typography>
      <AuthFields
        authType={"Finish Registration"}
        showUsername={true}
        apiMsg={apiMsg}
        onSubmit={onSubmit}
      />
    </Container>
  );
}
