export enum EventMsgType {
  MadeShots = 1,
  MissedShots = 2,
  FreeThrows = 3,
  Rebounds = 4,
  TurnoverSteals = 5,
  Foul = 6,
  Violations = 7,
  Subs = 8,
  Timeouts = 9,
  JumpBall = 10,
  Ejection = 11,
  StartQ = 12,
  EndQ = 13,
  Unknown = 18,
}
