import { useEffect, useMemo, useRef, useState } from "react";
// import logo from './logo.svg';

import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSearchParams } from "react-router-dom";
import { playerUI } from "../api/nba";
import { useDataContext } from "../context/nba-data";
import { Comparers, Season, comparers } from "../types/constants";
import { lineupDto, pbpVideoDto } from "../types/dto";
import {
  FilterType,
  Quarter,
  convertGameClockToSeconds,
  convertMinSecToSeconds,
  getParamsToSet,
  parseSearchParams,
} from "../utils/pbp-filter";
import NumCompareSelector from "./menu/NumCompareSelector";
import PlayersSelector from "./menu/PlayersSelector";
import QuarterSelector from "./menu/QuarterSelector";
import QuarterTimeSelector from "./menu/QuarterTimeSelector";
import GameSeasonMenu from "./GameSeasonMenu";
import PlayFilter from "./menu/PlayFilter";

const drawerWidth: number = 600;

interface situationMenuProps {
  lineups: lineupDto[];
  onFilterChange: (filterPbp: () => (pbp: pbpVideoDto) => boolean) => void;
  autoplay: boolean;
  setAutoplay: (newVal: boolean) => void;
}

const clutchMessage =
  "Clutch time means score is within 5 points and there are 5 minutes or fewer left in the game";

// on a pbpVideoDto the score is the value after the bucket goes in
// so if home bucket, we subtract home score by 3
// if away bucket we subtract away score by 3
const getScoreCorrectedPbp = (pbp: pbpVideoDto) => {
  let homeScore = pbp.homeScore;
  let awayScore = pbp.awayScore;
  if (pbp.eventMsgType === 1) {
    const scoreChange = pbp.is3Pt ? 3 : 2;
    if (pbp.isHome) homeScore -= scoreChange;
    else awayScore -= scoreChange;
  }
  return { ...pbp, homeScore, awayScore };
};

function SituationMenu(props: situationMenuProps) {
  const seasons = Object.values(Season);
  const [selectedSeason, setSelectedSeason] = useState(seasons[0]);

  const [selectedPlayers, setSelectedPlayers] = useState<playerUI[]>([]);
  const [filterPlays, setFilterPlays] = useState(
    () => (pbp: pbpVideoDto) => true,
  );
  const data = useDataContext();
  const selectedShotTypes = null;
  // const shotTypesAndEVAT = data.shotTypes;
  // const shotTypes = [all, ...Object.keys(shotTypesAndEVAT)];
  // const [selectedShotTypes, setSelectedShotTypes] = useState(shotTypes);
  // const [shotTypeSearch, setShotTypeSearch] = useState('');

  const [quarters, setQuarters] = useState([] as string[]);

  const [scoreComparer, setScoreComparer] = useState(comparers[0].value);
  const [scoreComparerValue, setScoreComparerValue] = useState(0);

  const [quarterTimeComparer, setQuarterTimeComparer] = useState(
    comparers[3].value,
  );
  const [quarterMin, setQuarterMin] = useState(12);
  const [quarterSec, setQuarterSec] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = parseSearchParams(searchParams);

  const seasonQs = searchParams.get("season") as Season;
  const gameIdQs = searchParams.get("gameId");
  const gameId = searchParams.get("gameId")
    ? Number(searchParams.get("gameId"))
    : null;

  const drawerRef = useRef<HTMLDivElement>(null);

  const [open, _setOpen] = useState(false);
  const setOpen = (newVal: boolean) => {
    _setOpen(newVal);
    if (newVal) drawerRef.current?.focus();
  };
  const toggleDrawer = () => setOpen(!open);

  const lineupPlayerIdsByLineupId = useMemo(() => {
    const toReturn = {} as { [key: number]: number[] };
    props.lineups.forEach((lineup) => {
      toReturn[lineup.lineupId] = lineup.lineupPlayerIds;
    });
    return toReturn;
  }, [props.lineups]);
  useEffect(() => {
    const filterQuarter = (p: pbpVideoDto) => {
      const pbp = getScoreCorrectedPbp(p);
      return (
        quarters.length === 0 ||
        (pbp.period === 1 && quarters.some((q) => q === Quarter.Q1)) ||
        (pbp.period === 2 && quarters.some((q) => q === Quarter.Q2)) ||
        (pbp.period === 3 && quarters.some((q) => q === Quarter.Q3)) ||
        (pbp.period === 4 && quarters.some((q) => q === Quarter.Q4)) ||
        (pbp.period > 4 && quarters.some((q) => q === Quarter.OT)) ||
        (pbp.period > 3 && quarters.some((q) => q === Quarter.Clutch)) // TODO fix clutch
      );
    };
    const filterScore = (p: pbpVideoDto) => {
      const pbp = getScoreCorrectedPbp(p);
      return (
        (scoreComparer === Comparers.GT &&
          Math.abs(pbp.homeScore - pbp.awayScore) > scoreComparerValue) ||
        (scoreComparer === Comparers.GTE &&
          Math.abs(pbp.homeScore - pbp.awayScore) >= scoreComparerValue) ||
        (scoreComparer === Comparers.LT &&
          Math.abs(pbp.homeScore - pbp.awayScore) < scoreComparerValue) ||
        (scoreComparer === Comparers.LTE &&
          Math.abs(pbp.homeScore - pbp.awayScore) <= scoreComparerValue)
      );
    };
    const filterLineup = (p: pbpVideoDto) => {
      const homeLineupPlayers =
        lineupPlayerIdsByLineupId[p.homeLineupId ?? 0] ?? [];
      const awayLineupPlayers =
        lineupPlayerIdsByLineupId[p.awayLineupId ?? 0] ?? [];
      return selectedPlayers.every(
        (player) =>
          homeLineupPlayers.includes(player.playerId) ||
          awayLineupPlayers.includes(player.playerId),
      );
    };
    const filterGameClock = (p: pbpVideoDto) => {
      const secs = convertMinSecToSeconds(quarterMin, quarterSec);
      const pbpSecs = convertGameClockToSeconds(p.gameClock);
      return (
        (quarterTimeComparer === Comparers.GT && pbpSecs > secs) ||
        (quarterTimeComparer === Comparers.GTE && pbpSecs >= secs) ||
        (quarterTimeComparer === Comparers.LT && pbpSecs < secs) ||
        (quarterTimeComparer === Comparers.LTE && pbpSecs <= secs)
      );
    };
    props.onFilterChange(
      () => (pbp: pbpVideoDto) =>
        filterQuarter(pbp) &&
        filterScore(pbp) &&
        filterLineup(pbp) &&
        filterGameClock(pbp) &&
        filterPlays(pbp),
    );
  }, [
    quarters,
    scoreComparer,
    scoreComparerValue,
    quarterTimeComparer,
    quarterMin,
    quarterSec,
    selectedPlayers,
    filterPlays,
  ]);

  useEffect(() => {
    setSelectedPlayers([]);
  }, [gameIdQs]);

  const setUnwatchedQS = (newVal: boolean) => {
    const newParams = getParamsToSet(
      FilterType.Unwatched,
      newVal ? "" : null,
      searchParams,
    );
    setSearchParams(newParams);
  };

  const gamePlayerIds = new Set<number>();
  props.lineups.forEach((lineup) =>
    lineup.lineupPlayerIds.forEach((playerId) => gamePlayerIds.add(playerId)),
  );
  console.log(data?.playersBySeason)
  const gamePlayers = data.playersBySeason[seasonQs].filter((x) =>
    gamePlayerIds.has(x.playerId),
  );
  const drawerContent = (
    <>
      <Toolbar
        sx={{
          position: "flex",
          width: "100%",
          justifyContent: "center",
          marginRight: "auto",
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Filter Videos
        </Typography>
        <IconButton
          onClick={toggleDrawer}
          sx={{ position: "absolute", right: 5, top: 5 }}
        >
          <Close />
        </IconButton>
      </Toolbar>

      <Grid
        container
        justifyContent="center"
        sx={{ textAlign: "center", padding: "10px", rowGap: "10px" }}
      >
        <Grid item xs={12}>
          <GameSeasonMenu selectedSeason={seasonQs} selectedGameId={gameId} />
        </Grid>
        <PlayFilter
          players={gamePlayers}
          onChange={(newFunc) => setFilterPlays(() => newFunc)}
        />

        <Grid item xs={12}>
          <PlayersSelector
            showAbbrs={true}
            players={gamePlayers}
            selectedPlayers={selectedPlayers}
            maxItems={10}
            label="Players on court"
            onChange={setSelectedPlayers}
          />
        </Grid>
        <Grid item xs={12}>
          <QuarterSelector quarters={quarters} setQuarters={setQuarters} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <QuarterTimeSelector
              comparer={quarterTimeComparer}
              minute={quarterMin}
              second={quarterSec}
              onChange={(newComparer, newMin, newSec) => {
                setQuarterTimeComparer(newComparer);
                setQuarterMin(newMin);
                setQuarterSec(newSec);
              }}
            />

            <NumCompareSelector
              numInputName="Score diff"
              comparer={scoreComparer}
              comparerLabel="Score diff is "
              numVal={scoreComparerValue}
              onChange={(scoreComparer, num) => {
                setScoreComparer(scoreComparer);
                setScoreComparerValue(num);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={props.autoplay}
                onChange={(e) => props.setAutoplay(e.target.checked)}
              />
            }
            label="Autoplay"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={filterParams[FilterType.Unwatched]}
                onChange={(e) => setUnwatchedQS(e.target.checked)}
              />
            }
            label="Unwatched plays only"
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={toggleDrawer}
            color="secondary"
            fullWidth
          >
            Close
          </Button>
        </Grid>

      </Grid>
    </>
  );

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Button
        variant="outlined"
        onClick={toggleDrawer}
        color="secondary"
        fullWidth
        sx={{ paddingX: 0 }}
        TouchRippleProps={{ style: { width: "100%" } }}
      >
        Situation
      </Button>
      <Drawer
        ref={drawerRef}
        // onBlur={() => setOpen(false)} doesn't work
        sx={{
          "& .MuiDrawer-paper": {
            zIndex: 1202, // TODO fix this
            margin: "auto",
            marginBottom: "10px",
            maxWidth: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#343434",
          },
        }}
        anchor={greaterThanMid ? "top" : "bottom"}
        variant="persistent"
        open={open}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

export default SituationMenu;
