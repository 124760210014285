import { useEffect, useMemo, useState } from "react";
// import logo from './logo.svg';

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { playerUI } from "../../api/nba";
import { useDataContext } from "../../context/nba-data";
import { teamsDto } from "../../types/dto";
import { getSortedTeams, searchTeams } from "../../utils/game-utils";

const all = "All";
const allO = "All Offense";
const allD = "All Defense";

interface teamSelectorProps {
  selectedTeams: teamsDto[];
  label: string;
  maxItems: number;
  onChange: (teams: teamsDto[]) => void;
}

export default function TeamSelector(props: teamSelectorProps) {
  const data = useDataContext();
  const allTeams = useMemo(() => {
    return getSortedTeams(Object.values(data.teams));
  }, []);
  return (
    <>
      <Autocomplete
        filterOptions={(options, { inputValue }) => {
          return searchTeams(options, inputValue);
        }}
        fullWidth
        size="small"
        limitTags={1}
        multiple={true}
        value={props.selectedTeams}
        onChange={(e, newVal) => {
          if (newVal.length <= props.maxItems) props.onChange(newVal);
          else if (props.maxItems === 1) props.onChange([newVal[1]]);
        }}
        options={allTeams}
        getOptionLabel={(team) => team.teamCity + " " + team.teamName}
        filterSelectedOptions
        renderInput={(params) => {
          return (
            <TextField
              color="secondary"
              {...params}
              InputLabelProps={{ shrink: true }}
              label={props.label}
            />
          );
        }}
      />
    </>
  );
}
