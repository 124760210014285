import { createContext, useContext } from "react";

export interface userContext {
  openAlert: boolean;
  setOpenAlert: (value: boolean) => void;
  alertText: string;
  setAlertText: (value: string) => void;
}

export const DefaultUserContext = () => {
  return {
    openAlert: false,
    setOpenAlert: (value: boolean) => {},
    alertText: "",
    setAlertText: (value: string) => {},
  };
};

export const UserContext = createContext<userContext>(DefaultUserContext());

export const useUserContext = () => useContext(UserContext);
