import { FollowType } from "../types/constants";
import { userDetailsDto, userDto } from "../types/dto";
import { apiGet, apiPostJson, AUTH_PREFIX } from "./utils";

export const login = async (throwAuthError: boolean) => {
  const res = await apiPostJson(
    `${AUTH_PREFIX}/login`,
    {},
    undefined,
    throwAuthError,
  );
  return res;
};

export const getUserDetails = async () => {
  const res = await apiGet(`${AUTH_PREFIX}/details`)
  const toReturn = await res.json();
  return toReturn as userDetailsDto;
}

export const userFollow = async (
  followedUserId: number,
  followType: FollowType,
) => {
  const data = { followedUserId, followType };
  await apiPostJson(`${AUTH_PREFIX}/follow`, data);
};

export const updateUserBio = async (
  bio: string,
) => {
  const data = { bio };
  await apiPostJson(`${AUTH_PREFIX}/bio`, data);
};

export const updateUserPrivacy = async (
  leader: boolean,
  bio: boolean,
  film: boolean,
  posts: boolean,
  network: boolean,
) => {
  const data = { leader, bio, film, posts, network };
  await apiPostJson(`${AUTH_PREFIX}/privacy`, data);
};

export const userUnfollow = async (
  followedUserId: number,
  followType: FollowType,
) => {
  const data = { followedUserId, followType };
  await apiPostJson(`${AUTH_PREFIX}/unfollow`, data);
};

export const register = async (
  email: string,
  username: string,
  uid: string,
) => {
  const data = { email, username, uid };
  const response = await apiPostJson(`${AUTH_PREFIX}/create`, data);
  return response;
};
