import Button from "@mui/material/Button";
import { useState } from "react";
import { userFollow, userUnfollow } from "../api/auth";
import { FollowType } from "../types/constants";

interface followButtonProps {
  loggedInUserId: number | null;
  followedUserId: number;
  userFollows: boolean;
  onClick: null | ((follow: boolean) => void);
}

export default function FollowButton(props: followButtonProps) {
  const { loggedInUserId, followedUserId } = props;

  const [userFollows, setUserFollows] = useState(props.userFollows);

  const onFollowClick = async () => {
    if (loggedInUserId && loggedInUserId !== followedUserId) {
      if (!userFollows) {
        await userFollow(followedUserId, FollowType.Follower);
        setUserFollows(true);
        if (props.onClick) props.onClick(true);
      } else {
        await userUnfollow(followedUserId, FollowType.Follower);
        setUserFollows(false);
        if (props.onClick) props.onClick(false);
      }
    }
  };
  return loggedInUserId === followedUserId ? (
    <></>
  ) : (
    <Button
      size="small"
      color="secondary"
      variant={userFollows ? "contained" : "outlined"}
      disabled={loggedInUserId === null || loggedInUserId === followedUserId}
      onClick={onFollowClick}
    >
      {userFollows ? "Following" : "Follow"}
    </Button>
  );
}
