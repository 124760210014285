import { useState } from "react";

import BackIcon from "@mui/icons-material/ArrowBack";
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import KeyIcon from "@mui/icons-material/Key";
import LockIcon from '@mui/icons-material/Lock';
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UserChangePassword from "../components/UserChangePassword";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";
import UserPrivacy from "../components/UserPrivacy";
import { useAuth } from "../context/auth-context";
enum SettingsCategory {
  Privacy = "Privacy",
  Password = "Change Password"
}

const categories = [
  { category: SettingsCategory.Privacy, icon: <LockIcon />, isPro: true },
  { category: SettingsCategory.Password, icon: <KeyIcon />, isPro: false }
]

interface userSettingsProps { }
export default function UserSettings(props: userSettingsProps) {
  const [selectedCat, setSelectedCat] = useState(null as SettingsCategory | null);

  const handleListItemClick = (
    cat: SettingsCategory,
  ) => {
    setSelectedCat(cat);
  };
  // Check if the screen size is xs (extra small)
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const authContext = useAuth();

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}>
          {
            (!isXs || selectedCat === null) &&
            <List component="nav" aria-label="main mailbox folders">
              {
                categories
                  .filter(cat => {
                    return (cat.category !== SettingsCategory.Privacy || authContext.userDetails !== null)
                    && (!cat.isPro || authContext.accountLevel.canPrivateProfile)
                  })
                  .map(cat => {
                    return (<ListItemButton
                      selected={selectedCat === cat.category}
                      onClick={(event) => handleListItemClick(cat.category)}
                    >
                      <ListItemIcon>
                        {cat.icon}
                      </ListItemIcon>
                      <ListItemText primary={cat.category} />
                      <ForwardIcon />
                    </ListItemButton>)
                  })
              }
            </List>
          }
        </Grid>
        <Grid item xs={12} sm={8} padding='10px'>
          {
            selectedCat !== null &&
            <>
              <Typography variant="h6">
                <IconButton sx={{ marginX: '15px' }} onClick={() => setSelectedCat(null)}>
                  <BackIcon />
                </IconButton>
                {selectedCat}
              </Typography>
              {
                selectedCat === SettingsCategory.Privacy && <UserPrivacy />
              }
              {
                selectedCat === SettingsCategory.Password && <UserChangePassword />
              }
            </>
          }
        </Grid>
      </Grid>
    </>
  );
}
