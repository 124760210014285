import { useState } from "react";
import AuthFields from "./AuthFields";

import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  changePassword,
  getCurrentUser,
  getFirebaseErrorMsg,
} from "../api/firebase";

interface userChangePasswordProps {}
export default function UserChangePassword(props: userChangePasswordProps) {
  const [apiMsg, setApiMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  const onChange = async (
    username: string,
    email: string,
    password: string,
    verifyPassword: string,
  ) => {
    if (!getCurrentUser()) {
      setApiMsg("Must log in to change password");
    } else if (password !== verifyPassword) {
      setApiMsg("Passwords don't match");
    } else if (password.length < 6) {
      setApiMsg("Password must be at least 6 characters");
    } else {
      setApiMsg(null);
      try {
        await changePassword(password);
      } catch (err) {
        setApiMsg(getFirebaseErrorMsg(err));
        return;
      }
      return navigate("/");
    }
    // const res = await changePassword(password);
    // if (res.status > 399)
    //   setApiMsg("There was an issue changing your password.");
    // else
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ textAlign: "left", marginLeft: '0px', mt: "10px" }}
    >
      <AuthFields
        authType={"Change Password"}
        showPassword={true}
        showVerifyPassword={true}
        apiMsg={apiMsg}
        onSubmit={onChange}
      />
    </Container>
  );
}
