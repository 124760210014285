import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FacebookIcon from "@mui/icons-material/Facebook";
import RedditIcon from "@mui/icons-material/Reddit";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import {
    copyTextToClipboard,
    fallbackCopyTextToClipboard
} from "../utils/clipboard-utils";
import {
    createFacebookShareUrl,
    createRedditShareUrl,
    createTwitterShareUrl
} from "../utils/url-utils";
interface shareMenuProps {
    shareText: string;
    linkPath: string,
    // children: ReactJSXElement
}
export default function ShareMenu(props: shareMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { linkPath, shareText } = props;

    const onReddit = () => {
        window.open(createRedditShareUrl(shareText, linkPath), "_blank");
        handleClose();
    };
    const onFacebook = () => {
        window.open(createFacebookShareUrl(linkPath), "_blank");
        handleClose();
    };
    const onTwitter = () => {
        window.open(createTwitterShareUrl(shareText, linkPath), "_blank");
        handleClose();
    };
    const onCopy = () => {
        copyTextToClipboard(linkPath);
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-label="share"
                onClick={handleClick}
                sx={{ fontSize: "1.2rem" }}
            >
                <ShareIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={onTwitter} component='button'>
                    <ListItemIcon>
                        <TwitterIcon />
                    </ListItemIcon>
                    Twitter
                </MenuItem>
                <MenuItem onClick={onReddit} component='button'>
                    <ListItemIcon>
                        <RedditIcon />
                    </ListItemIcon>
                    Reddit
                </MenuItem>
                <MenuItem onClick={onFacebook} component='button'>
                    <ListItemIcon>
                        <FacebookIcon />
                    </ListItemIcon>
                    Facebook
                </MenuItem>
                <MenuItem onClick={onCopy} component='button'>
                    <ListItemIcon>
                        <ContentPasteIcon />
                    </ListItemIcon>
                    Copy Link
                </MenuItem>
            </Menu>
        </>
    );
}
