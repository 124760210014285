import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FacebookIcon from "@mui/icons-material/Facebook";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RedditIcon from "@mui/icons-material/Reddit";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getAccountAuth } from "../api/firebase";
import { likeNote, unlikeNote, upsertNote } from "../api/pbp-user";
import { useAuth } from "../context/auth-context";
import { useDataContext } from "../context/nba-data";
import { League, NotePrivacies, NotePrivacy } from "../types/constants";
import { noteAndPbpDto } from "../types/dto";
import { games } from "../types/ui";
import { AccountLevel, accountAuthorizationsByLevel } from "../types/user-authorization";
import {
  fallbackCopyTextToClipboard
} from "../utils/clipboard-utils";
import AddIcon from '@mui/icons-material/Add';
import {
  createClipPathExternal,
  createFacebookShareUrl,
  createRedditShareUrl,
  createTwitterShareUrl,
  createUserProfilePath
} from "../utils/url-utils";
import FollowButton from "./FollowButton";
import GameInfo from "./GameInfo";
import NoteText from "./NoteText";
import VideoPlayer from "./VideoPlayer";
import SavePlaylistButton from "./SavePlaylistButton";
interface notePrivacySelectProps {
    privacy: NotePrivacy;
    onChange: (privacy: NotePrivacy) => void;
}
export default function NotePrivacySelect(props: notePrivacySelectProps) {
    const [accountAuth, setAccountAuth] = useState(accountAuthorizationsByLevel[AccountLevel.BASIC]);
    useEffect(() => {
      getAccountAuth().then(accLvl => {
        setAccountAuth(accLvl);
      });
    }, []);
    
    const setNotePrivacy = (privacy: NotePrivacy) => {
        props.onChange(privacy);
    }
    return <FormControl>
    <InputLabel color="secondary">Privacy</InputLabel>
    <Select
      color="secondary"
      className="search-fields"
      size="small"
      label="Privacy"
      value={props.privacy}
      onChange={(e) => setNotePrivacy(e.target.value as NotePrivacy)}
    >
      {NotePrivacies.map((np) => (
        <MenuItem
          color="secondary"
          key={np}
          value={np}
          // disabled if it's not public, and you can't private post
          disabled={np !== NotePrivacy.Public && !accountAuth.canPrivatePost}
        >
          {np.charAt(0).toUpperCase() + np.slice(1)}
        </MenuItem>))}
    </Select>
  </FormControl>
}