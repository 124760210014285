export enum Season {
  Y2025 = "2024-25",
  Y2024 = "2023-24",
  Y2023 = "2022-23",
  Y2022 = "2021-22",
  Y2021 = "2020-21",
  Y2020 = "2019-20",
  Y2019 = "2018-19",
  Y2018 = "2017-18",
  Y2017 = "2016-17",
  Y2016 = "2015-16",
  Y2015 = "2014-15",
}

export const latestSeason = Season.Y2025
export const allSeasons = [
  Season.Y2025,
  Season.Y2024,
  Season.Y2023,
  Season.Y2022,
  Season.Y2021,
  Season.Y2020,
  Season.Y2019,
  Season.Y2018,
  Season.Y2017,
  Season.Y2016,
  Season.Y2015,
];

export enum League {
  NBA = "nba",
  WNBA = "wnba",
}

export enum Positions {
  Guard = "Guard",
  Forward = "Forward",
  Big = "Big",
}

export enum NotePrivacy {
  Public = "public",
  Followers = "followers",
  // Friends = "friends",
  Private = "private",
}

export const NotePrivacies = [
  NotePrivacy.Public,
  NotePrivacy.Followers,
  // NotePrivacy.Friends,
  NotePrivacy.Private,
];

export enum LikedItemType {
  Post = "post",
  Playlist = "playlist"
}

export enum Comparers {
  GTE = "gte",
  GT = "gt",
  LT = "lt",
  LTE = "lte",
}

export const comparers = [
  { value: Comparers.GTE, text: "≥" },
  { value: Comparers.GT, text: ">" },
  { value: Comparers.LT, text: "<" },
  { value: Comparers.LTE, text: "≤" },
];

export const VIDEO_VIEW_TOTAL_TEAM_ID = 0;

export enum FollowType {
  Follower = "follower",
}

export enum FollowFilter {
  ALL = "All",
  FOLLOWING = "Following",
  FRIENDS = "Friends",
}

export enum PlayerPlayTypes {
  TWOPT='0',
  THREEPT='1',
  MAKE='2',
  MISS='3',
  AST='4',
  ASTD='5',
  UASTD='6',
  FT='7',
  TOV='8',
  OREB='9',
  D2PT='10',
  D3PT='11',
  DMAKE='12',
  DMISS='13',
  DASTD='14',
  DUASTD='15',
  DBLK='16',
  DREB='17',
  DTOV='18',
  DFOUL='19'
}

export const offShotPlayTypes = new Set([PlayerPlayTypes.TWOPT, PlayerPlayTypes.THREEPT, PlayerPlayTypes.MAKE, PlayerPlayTypes.MISS, PlayerPlayTypes.ASTD, PlayerPlayTypes.UASTD]);
export const defShotPlayTypes = new Set([PlayerPlayTypes.D2PT, PlayerPlayTypes.D3PT, PlayerPlayTypes.DMAKE, PlayerPlayTypes.DMISS, PlayerPlayTypes.DASTD, PlayerPlayTypes.DUASTD]);
export const allPlayerPlayTypes = Object.values(PlayerPlayTypes);
export const uniquePlayerPlayTypes = new Set(allPlayerPlayTypes);

export enum SortType {
  ASC ='asc',
  DESC = 'desc'
}

export const validSortTypes = new Set(Object.values(SortType));

export const MAX_PRIVATE_PLAYLIST_COUNT = 100;
export const MAX_PLAYLIST_PLAY_COUNT = 100;
