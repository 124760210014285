import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useDataContext } from "../context/nba-data";

import { Box, Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getViewLeaders } from "../api/user";
import { Season } from "../types/constants";
import { viewLeaderDto } from "../types/dto";
import { createUserProfilePath } from "../utils/url-utils";

enum LeaderType {
  TOTAL = "total",
  SEASON = "season",
  MONTH = "month",
  WEEK = "week",
}

interface viewsLeaderboardProps {}

const defaultLeaders = {
  total: [],
  season: [],
  month: [],
  week: [],
};

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "rank", label: "Rank" },
  { id: "name", label: "User" },
  {
    id: "views",
    label: "Views",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "viewTime",
    label: "View Time",
    format: (value: number) => `${value.toLocaleString("en-US")} sec`,
  },
];

export default function ViewsLeaderboard(props: viewsLeaderboardProps) {
  const seasons = Object.values(Season);
  const [leaderType, setLeaderType] = useState(LeaderType.TOTAL);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(0);
  const [leaders, setLeaders] = useState<viewLeaderDto>(defaultLeaders);
  const data = useDataContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("userId"));

  useEffect(() => {
    getViewLeaders(selectedTeamId).then((l) => setLeaders(l));
  }, [selectedTeamId]);
  const teamsById = data.teams;
  // todo pick a team. or deserialize stp into list of players
  // maybe make the games reference team id and another endpoint to get the team

  return (
    <Container>
      <Grid container>
        <Box
          component="img"
          sx={{
            verticalAlign: "middle",
            // width: 350,
            // maxHeight: { xs: 233, md: 167 },
            maxWidth: "100%",
          }}
          src="/Leaderboard_header.png"
        />
        <ToggleButtonGroup
          color="secondary"
          value={leaderType}
          fullWidth
          size="small"
          exclusive
          onChange={(e, newType) => setLeaderType(newType)}
        >
          <ToggleButton value={LeaderType.TOTAL}>All Time</ToggleButton>
          <ToggleButton value={LeaderType.SEASON}>Season</ToggleButton>
          <ToggleButton value={LeaderType.MONTH}>Month</ToggleButton>
          <ToggleButton value={LeaderType.WEEK}>Week</ToggleButton>
        </ToggleButtonGroup>

        <Select
          size="small"
          value={selectedTeamId}
          sx={{ margin: "auto", mt: "7px" }}
          onChange={(e) =>
            setSelectedTeamId(parseInt(e.target.value.toString()))
          }
        >
          <MenuItem value={0}>All teams</MenuItem>
          {Object.values(teamsById).map((team) => {
            return (
              <MenuItem key={team.teamId} value={team.teamId}>
                {team.teamCity + " " + team.teamName}
              </MenuItem>
            );
          })}
        </Select>

        <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "0px" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {leaders[leaderType]?.map((leader, i) => {
                  const userProfilePath = createUserProfilePath(leader.userId);
                  return (
                    <TableRow hover key={`leaderRow${i}`} tabIndex={-1}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>
                        <Link style={{ color: "white" }} to={userProfilePath}>
                          {leader.username}
                        </Link>
                      </TableCell>
                      <TableCell>{leader.views}</TableCell>
                      <TableCell>{leader.viewTime}</TableCell>
                    </TableRow>
                  );
                })}

                {/* {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (

                );
              })} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
      </Grid>

      {
        // selectedPlayer !== null && <Grid container>
        //     {
        //         ratings.map((rating, i) => {
        //             const setRating = (newVal: number | null) => {
        //                 ratings[i].score = newVal;
        //                 setRatings([...ratings]);
        //             }
        //             return (<RatingScore type={rating.name} score={rating.score} setRating={setRating} />);
        //         })
        //     }
        // </Grid>
      }
    </Container>
  );
}
