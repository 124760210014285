import { useEffect, useMemo, useRef, useState } from "react";
import { useDataContext } from "../context/nba-data";

import { League, Season } from "../types/constants";
import { pbpVideoDto } from "../types/dto";

import { useUserContext } from "../context/user-data";
import { games } from "../types/ui";
import TimeTracker, { VIEW_TIME_MIN } from "../utils/time-tracker";
import { upsertView } from "../api/pbp-user";
import { useAuth } from "../context/auth-context";
import VideoBase from "./VideoBase";
interface videoPlayerProps {
  pauseTrigger: boolean;
  pbpVideoId: number;
  videoUrl: string;
  videoHeight: number;
  onVideoLoad: () => void;
  onView: null | (() => void);
  onEnded: () => void;
}

export default function VideoPlayer(props: videoPlayerProps) {
  const data = useDataContext();
  const [pbpVideoId, setPbpVideoId] = useState(props.pbpVideoId);
  const [videoHeight, setVideoHeight] = useState(props.videoHeight);
  const vidRef = useRef<HTMLVideoElement>(null);
  // const [season, setSeason] = useState(props.season);
  const userAuth = useAuth();
  const pauseRef = useRef(props.pauseTrigger);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const timeTracker = useMemo(() => new TimeTracker(), []);

  const validateView = (pbpVideoId: number) => {
    clearTimer();
    const time = timeTracker.checkTime(true);
    const alreadyChecked = time.timeSinceLastCheck !== time.totalElapsedTime;

    if (alreadyChecked || time.totalElapsedTime > VIEW_TIME_MIN) {
      const elapsedTimeMs = alreadyChecked
        ? time.timeSinceLastCheck
        : time.totalElapsedTime;
      if (!alreadyChecked && props.onView !== null) props.onView();
      upsertView(
        userAuth.userId,
        League.NBA,
        pbpVideoId,
        Math.round(elapsedTimeMs / 1000),
        !alreadyChecked,
      );
    }
  };

  useEffect(() => {
    const hasChanged = props.pbpVideoId !== pbpVideoId;
    if (hasChanged) {
      validateView(props.pbpVideoId);
      setPbpVideoId(props.pbpVideoId);
      // do not update on props.season change because season changes before pbp is fetched
      // if (props.pbpToRender.videoUrl === pbpToRender.videoUrl)
      //   vidRef.current?.load();
    }
  }, [props.pbpVideoId]);
  useEffect(() => setVideoHeight(props.videoHeight), [props.videoHeight]);
  useEffect(() => {
    if (vidRef.current && pauseRef.current !== props.pauseTrigger){
      vidRef.current.pause();
      pauseRef.current = props.pauseTrigger;
    }
  }, [props.pauseTrigger]);
  // start timeTracker, set timeout if valid
  const onPlay = () => {
    timeTracker.start();
    const time = timeTracker.checkTime(false);
    // set timeout if we never "checked". if we reached, we can assume we good
    const shouldSetTimer = time.timeSinceLastCheck === time.totalElapsedTime;
    if (shouldSetTimer) {
      const timeMs = Math.max(0, VIEW_TIME_MIN - time.totalElapsedTime + 100);
      timerIdRef.current = setTimeout(
        () => validateView(props.pbpVideoId),
        timeMs,
      );
    }
  };

  const clearTimer = () => {
    if (timerIdRef.current !== null) {
      clearTimeout(timerIdRef.current);
      timerIdRef.current = null;
    }
  };
  return (
    <>
      <div style={{ margin: "auto" }}>
        <VideoBase
          videoHeight={videoHeight}
          vidRef={vidRef}
          videoUrl={props.videoUrl}
          onLoadedData={() => {
            props.onVideoLoad();
            timeTracker.restart();
          }}
          onPause={() => {
            clearTimer();
            timeTracker.pause();
          }}
          onPlay={onPlay}
          onEnded={() => {
            validateView(props.pbpVideoId);
            props.onEnded();
          }}
        />
        {/* {
                curGame !== undefined &&
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant='body2' sx={{
                            fontSize: { xs: '0.8rem', sm: '0.875rem', md: '0.95rem' },
                            textAlign: { xs: 'center', sm: 'right' },
                            pr: { xs: 0, sm: '10px' }
                        }}>
                            <span style={{ ...teamHighlightStyle, backgroundColor: data.teams[curGame.homeTeamId].teamColor }}>
                                {getHomeName(curGame, data.teams)}</span> {pbpToRender.homeScore} -&nbsp;
                            <span style={{ ...teamHighlightStyle, backgroundColor: data.teams[curGame.awayTeamId].teamColor }}>
                                {getAwayName(curGame, data.teams)}</span> {pbpToRender.awayScore}&nbsp;&nbsp;
                            <Typography variant='caption' component="span">({pbpToRender.period}Q {pbpToRender.gameClock})</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='body2' sx={{
                            fontSize: { xs: '0.8rem', sm: '0.875rem', md: '0.95rem' },
                                textAlign: {xs: 'center', sm: 'left'},
                                pl: {xs: 0, sm: '10px'}
                            }}>
                                {pbpToRender.playDesc}
                        </Typography>
                    </Grid>
                </Grid>
            } */}
      </div>
    </>
  );
}
