import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDataContext } from "../context/nba-data";

import { useSearchParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SaveIcon from '@mui/icons-material/Save';
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { getAccountAuth } from "../api/firebase";
import { updateEditLater, upsertNote } from "../api/pbp-user";
import { APPBAR_HEIGHT, calculateMaxVideoSize, MAX_POST_SIZE_PX } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import { League, NotePrivacies, NotePrivacy, Season } from "../types/constants";
import {
  pbpVideoDto,
  pbpVideoLineups,
  userPbpVideoItemDto,
} from "../types/dto";
import { accountAuthorizationsByLevel, AccountLevel } from "../types/user-authorization";
import { useDebounce } from "../utils/debounce-utils";
import { FilterType, parseSearchParams } from "../utils/pbp-filter";
import GameInfo from "./GameInfo";
import SavePlaylistButton from "./SavePlaylistButton";
import VideoPlayer from "./VideoPlayer";
import VideoPager from "./VideoPager";
enum ReviewMode {
  TAGS,
  REVIEW,
}

interface videoWorkspaceProps {
  autoplay: boolean;
  pbpVideoLineups: pbpVideoLineups;
  isEditLater: boolean;
  onEditLater: (pbpVideoId: number, isEditLater: boolean) => void;
  onUpdateNote: (pbpVideoId: number, userPbpVideo: userPbpVideoItemDto) => void;
}

interface pbpFilters {
  playerIds: Set<number>;
}

const filterPbp = (pbpAndLineups: pbpVideoLineups, pbpFilters: pbpFilters) => {
  // do not display two identical URLs consecutively
  // this will cause the video element to not update and play the next new video
  const filteredLineupIds = new Set(
    pbpAndLineups.lineups
      .filter((x) => x.lineupPlayerIds.some((y) => pbpFilters.playerIds.has(y)))
      .map((y) => y.lineupId),
  );
  let lastUrl = "";
  return pbpAndLineups.pbpVideo.filter((x) => {
    const urlSame = lastUrl === x.videoUrl;
    lastUrl = x.videoUrl;
    return (
      ((x.homeLineupId !== null && filteredLineupIds.has(x.homeLineupId)) ||
        (x.awayLineupId !== null && filteredLineupIds.has(x.awayLineupId))) &&
      !urlSame
    );
  });
};

let lastVideoTime = 0;
const shouldSetVideoIndex = (index: number, pbp: pbpVideoDto[]) => {
  if (index >= 0 && index <= pbp.length - 1) return true;
  return false;
};

interface noteState extends userPbpVideoItemDto {
  isDb: boolean;
  isEdited: boolean;
}

interface gameNotes {
  [key: string]: noteState;
}

const defaultNote = () => {
  return {
    note: "",
    notePrivacy: NotePrivacy.Public,
    isEditLater: false,
    isDb: false,
    isEdited: false,
  } as noteState;
};

const blink = "";
// const blink = keyframes`
//     0% {
//     color: inherit; /* Initial color */
//   }
//   10% {
//     color: yellow; /* Color to blink */
//   }
//   20% {
//     color: inherit; /* Back to initial color */
//   }
// `;

// const debounceSetIndex = (cb: Function) => debounce(cb, 1500);
export default function VideoWorkspace(props: videoWorkspaceProps) {
  const [videoIndex, _setVideoIndex] = useState(0);
  const [videoIndexDisplay, _setVideoIndexDisplay] = useState(0);
  const data = useDataContext();
  const userAuth = useAuth();
  const [gamesPbp, setGamesPbp] = useState({} as pbpVideoLineups);
  const [gameNote, _setGameNote] = useState(defaultNote());
  const [gameNotes, setGameNotes] = useState({} as gameNotes);
  const [isPlayerDrawerOpen, setIsPlayerDrawerOpen] = useState(false);
  const [isSituationDrawerOpen, setIsSituationDrawerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = parseSearchParams(searchParams);

  const [numPlaysWatched, setNumPlaysWatched] = useState(0);
  const [bottomContentOffset, setBottomContentOffset] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [screenWidthGtHt, setScreenWidthGtHt] = useState(true);
  const firstChildRef = useRef<HTMLDivElement>(null);
  const [reviewMode, setReviewMode] = useState(ReviewMode.TAGS);
  const [isReviewPbp, setIsReviewPbp] = useState(false);
  const [pauseTrigger, setPauseTrigger] = useState(false);
  const [accountAuth, setAccountAuth] = useState(accountAuthorizationsByLevel[AccountLevel.BASIC]);
  useEffect(() => {
    getAccountAuth().then(accLvl => {
      setAccountAuth(accLvl);
    });
  }, []);
  useEffect(() => {
    const pbpLineup = props.pbpVideoLineups;
    if (pbpLineup.userPbpVideo !== undefined) {
      const notes: gameNotes = {};
      const videoChanged =
        pbpLineup.pbpVideo[0]?.gameId === gamesPbp.pbpVideo?.[0]?.gameId;
      // setGameVideo(pbpLineup.pbpVideo);
      Object.keys(pbpLineup.userPbpVideo).forEach((pbpVideoId) => {
        if (pbpLineup.userPbpVideo[pbpVideoId].note !== null) {
          notes[pbpVideoId] = {
            ...pbpLineup.userPbpVideo[pbpVideoId],
            isDb: true,
            isEdited: false,
          };
        }
      });
      const curPbpVidId = pbpLineup?.pbpVideo[0]?.pbpVideoId;
      const curNote = notes[curPbpVidId] ?? defaultNote();
      if (videoChanged) {
        setVideoIndex(0);
        setGameNote(0, curNote);
        setIsEditLater(getIsEditLater(pbpLineup.pbpVideo[0], pbpLineup));
      }
      setGameNotes(notes);
      setGamesPbp(pbpLineup);
      // const playerIds = new Set<number>([playerId]);
      // const pbpFilters = { playerIds };
      // TODO this needs to also reset your workspace state
    }
  }, [props.pbpVideoLineups]);

  const gameVideo = useMemo(() => {
    return (gamesPbp.pbpVideo ?? []).filter(
      (p) =>
        props.isEditLater ||
        !filterParams[FilterType.Unwatched] ||
        gamesPbp?.userPbpVideo?.[p.pbpVideoId] === undefined,
    );
  }, [searchParams, gamesPbp, props.isEditLater]); // TODO more conditions
  const pbp = gameVideo[videoIndex];
  const getUserPbpVideo = (
    pbpParam: pbpVideoDto,
    pbpVidLineups: pbpVideoLineups,
  ) => {
    return pbpVidLineups.userPbpVideo?.[pbpParam?.pbpVideoId];
  };
  const getIsEditLater = (
    pbpParam: pbpVideoDto,
    pbpVidLineups: pbpVideoLineups,
  ) => {
    return getUserPbpVideo(pbpParam, pbpVidLineups)?.isEditLater ?? false;
  };

  const [isEditLater, setIsEditLater] = useState(
    getIsEditLater(props.pbpVideoLineups.pbpVideo[0], props.pbpVideoLineups),
  );
  // TODO figure out what this does
  const handleSizeChange = () => {
    if (firstChildRef.current) {
      setBottomContentOffset(
        firstChildRef.current.offsetHeight + APPBAR_HEIGHT,
      );
      // TODO make this min of 50% VH vs this
      setVideoHeight(
        Math.min(
          firstChildRef.current.offsetWidth * 0.5625,
          window.innerHeight * 0.5,
        ),
      );
    }
    setScreenWidthGtHt(window.innerWidth > window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);
  }, []);
  useEffect(handleSizeChange, [firstChildRef.current]);


  const setGameNote = (pbpVideoId: number, note: noteState) => {
    _setGameNote({ ...note });
    if (gameNotes[pbpVideoId] === undefined) {
      gameNotes[pbpVideoId] = note;
    }
  };


  const setVideoIndex = (i: number) => {
    _setVideoIndex(i);
    _setVideoIndexDisplay(i);
    const pbpVideo = gamesPbp.pbpVideo ?? {};
    const pbpVideoId = pbpVideo[i]?.pbpVideoId;
    setIsEditLater(getIsEditLater(pbpVideo[i], gamesPbp));
    const note = gameNotes[pbpVideoId] ?? defaultNote();
    setGameNote(pbpVideoId, note);
  };

  const userLoggedIn = userAuth.userId !== null;
  const loginTitle = userLoggedIn ? "" : "Log in to post a clip!";

  const handleVideoChange = (i: number) => {
    if (shouldSetVideoIndex(i, gameVideo)) setVideoIndex(i);
  };
  const prevVideo = () => handleVideoChange(videoIndex - 1);
  const nextVideo = () => handleVideoChange(videoIndex + 1);
  const pbpNote = gameNotes[pbp?.pbpVideoId] ?? defaultNote();
  const onSubmitNote = (noteState: noteState) => {
    upsertNote(
      userAuth.userId,
      League.NBA,
      pbp.season,
      pbp.gameId,
      pbp.pbpVideoId,
      noteState.note,
      noteState.notePrivacy,
    ).then((x) => {
      noteState.isDb = true;
      noteState.isEdited = false;
      setGameNote(pbp.pbpVideoId, noteState);
      props.onUpdateNote(pbp.pbpVideoId, noteState);
    });
  };

  const debounceSetIndex = useDebounce(
    () => handleVideoChange(videoIndexDisplay),
    1000,
  );
  const onSearchChange = (newIndex: number) => {
    _setVideoIndexDisplay(newIndex);
    debounceSetIndex();
  };

  const curGame = pbp?.season ? data.gamesBySeason[pbp.season].find(
    (x) => x.gameId === pbp.gameId,
  ) : undefined;

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: calculateMaxVideoSize(),
          flex: "1 1 auto",
        }}
      >
        {gameVideo.length < 1 ? <Typography margin='auto' marginTop='8px'>No videos</Typography> : <>
          <VideoPlayer
            pauseTrigger={pauseTrigger}
            onEnded={() => props.autoplay && nextVideo()}
            pbpVideoId={pbp.pbpVideoId}
            videoUrl={pbp.videoUrl}
            videoHeight={videoHeight}
            // onVideoLoad={() => {}}
            onVideoLoad={handleSizeChange}
            onView={() => setNumPlaysWatched(numPlaysWatched + 1)}
          />
          <Grid item xs={3} paddingX='2px'>
            <Button
              sx={{ paddingY: 0 }}
              variant="contained"
              color="secondary"
              fullWidth
              disabled={!userLoggedIn || pbpNote.isDb}
              onClick={async () => {
                setIsEditLater(!isEditLater);
                try {
                  await updateEditLater(
                    userAuth.userId,
                    League.NBA,
                    pbp.season,
                    pbp.gameId,
                    pbp.pbpVideoId,
                    !isEditLater,
                  );
                  props.onEditLater(pbp.pbpVideoId, !isEditLater);
                } catch (e) {
                  setIsEditLater(isEditLater);
                  throw e;
                }
              }}
            >
              {(pbpNote.isDb && "Posted") ||
                (isEditLater && "Unsave") ||
                <><SaveIcon />Save</>}
            </Button>
          </Grid>
          <Grid item xs={3} paddingX='2px'>
            <SavePlaylistButton pbp={pbp}
              button={<Button disabled={!userLoggedIn}
              sx={{paddingY: 0}}
                variant="contained" color="secondary" fullWidth>
                <AddIcon />Add
              </Button>} />
          </Grid>
          <Grid item xs={3} paddingX='2px'>
            <Button sx={{ paddingY: 0 }} onClick={prevVideo} disabled={videoIndex < 1} variant="contained" fullWidth>
              <SkipPreviousIcon />
              Prev
            </Button>
          </Grid>
          <Grid item xs={3} paddingX='2px'>
            <Button sx={{paddingY: 0}} onClick={nextVideo} disabled={videoIndex >= gameVideo.length - 1} variant="contained" fullWidth>
              Next
              <SkipNextIcon />
            </Button>
          </Grid>
          {curGame !== undefined && gameVideo.length > 0 && (
            <>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ textAlign: { xs: "center", sm: "right" } }}
              >
                <GameInfo
                  showOverflow={true}
                  pbp={pbp}
                  game={curGame}
                  showClock={true}
                  showDate={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: '8px',
                    fontSize: { xs: "0.8rem", sm: "0.875rem", md: "0.95rem" },
                    textAlign: { xs: "center", sm: "left" },
                    pl: { xs: 0, sm: "10px" },
                  }}
                >
                  {pbp.playDesc}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container sx={{ justifyContent: "space-evenly", marginTop: 1 }}>
              <VideoPager isPlaylist={false} videoIndex={videoIndex} maxIndex={gameVideo.length} onVideoIndexChange={handleVideoChange} />
              <Typography
                variant="body2"
                sx={{
                  verticalAlign: "bottom",
                  display: "inline-block",
                  lineHeight: "1.8em",
                }}
              >
                Session:&nbsp;&nbsp;
                {numPlaysWatched}{" "}
                <RemoveRedEyeIcon
                  sx={{
                    verticalAlign: "bottom",
                    animation: `${blink} 1s infinite ease`,
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ marginY: 0.5, textAlign: "center" }}
            title={loginTitle}
          >
            <TextField
              onFocus={() => setPauseTrigger(!pauseTrigger)}
              // onBlur={() => setPauseTrigger(true)}
              disabled={!userLoggedIn}
              sx={{ maxWidth: MAX_POST_SIZE_PX, width: "100%" }}
              multiline
              size="small"
              minRows={2}
              //   maxRows={4}
              placeholder="Post your thoughts here..."
              variant="outlined"
              value={pbpNote.note}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                pbpNote.note = event.target.value;
                pbpNote.isEdited = true;
                setGameNote(pbp.pbpVideoId, pbpNote);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
            title={loginTitle}
          >
            <ToggleButtonGroup
              sx={{ marginRight: "10px" }}
              disabled={!userLoggedIn}
              color="secondary"
              value={pbpNote.notePrivacy}
              // fullWidth
              size="small"
              exclusive
              onChange={(e, newPrivacy) => {
                if (newPrivacy) {
                  pbpNote.notePrivacy = newPrivacy;
                  pbpNote.isEdited = true;
                  setGameNote(pbp.pbpVideoId, pbpNote);
                }
              }}
            >
              {NotePrivacies.map((np) => (
                <ToggleButton
                  key={np}
                  value={np}
                  // disabled if it's not public, and you can't private post
                  disabled={np !== NotePrivacy.Public && !accountAuth.canPrivatePost}
                >
                  {np}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Button
              disabled={!userLoggedIn || !pbpNote.isEdited}
              variant="contained"
              sx={{ marginLeft: "10px" }}
              onClick={() => {
                const orig = isEditLater;
                setIsEditLater(false);
                try {
                  onSubmitNote(pbpNote);
                  props.onEditLater(pbp.pbpVideoId, false);
                } catch (e) {
                  setIsEditLater(orig);
                  throw e;
                }
              }}
            >
              {pbpNote.isDb ? "Edit" : "Post"}
            </Button>
          </Grid>
        </>
        }
      </Grid>
    </>
  );
}
