export const isNullOrWhitespace = (str: string | null | undefined) => {
  return (
    str === null ||
    str === undefined ||
    str.length === 0 ||
    str.trim().length === 0
  );
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const splitUserInput = (query: string) => {
  const splitQuery = query.split(/[\s*]+/).map((x) => x.toLocaleLowerCase());
  return splitQuery;
}