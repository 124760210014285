import { createContext, useContext } from "react";
import { playerBySeason, playerUI } from "../api/nba";
import {
  gamesBySeasonDto,
  roleSkillsDto,
  seasonTeamPlayersDto,
  teamByIdDto,
} from "../types/dto";
import { gamesBySeason } from "../types/ui";

export interface dataContext {
  gamesBySeason: gamesBySeason;
  seasonTeamPlayers: seasonTeamPlayersDto;
  playersBySeason: playerBySeason;
  allPlayers: playerUI[];
  teams: teamByIdDto;
  skills: roleSkillsDto[];
  shotTypes: { [key: string]: number[] };
}

export const DefaultDataContext = () => {
  return {
    gamesBySeason: {} as gamesBySeason,
    seasonTeamPlayers: {} as seasonTeamPlayersDto,
    playersBySeason: {} as playerBySeason,
    allPlayers: [] as playerUI[],
    teams: {} as teamByIdDto,
    skills: [] as roleSkillsDto[],
    shotTypes: {} as { [key: string]: number[] },
  };
};

export const DataContext = createContext<dataContext>(DefaultDataContext());

export const useDataContext = () => useContext(DataContext);

export const getAllPlayers = (playersBySeason: playerBySeason) => {
  const toReturn = [] as playerUI[];
  const seenPlayerIds = new Set<number>();
  Object.keys(playersBySeason).forEach((season) => {
    playersBySeason[season].forEach((player) => {
      if (seenPlayerIds.has(player.playerId)) return;
      seenPlayerIds.add(player.playerId);
      toReturn.push(player);
    });
  });
  return toReturn;
}