export enum AccountLevel {
    BASIC = 1,
    STANDARD = 2,
    PREMIUM = 3,
}

export interface AccountAuthorization {
    accountLevel: AccountLevel,
    displayName: string,
    canPrivatePost: boolean,
    canPrivateProfile: boolean,
    canBulkDownload: boolean,
    canFilter: boolean,
    canLinkPrivate: boolean,
    // canLeaderboard: boolean???
}

export const BASIC_ACCOUNT : AccountAuthorization = {
    accountLevel: AccountLevel.BASIC,
    displayName: "Basic",
    canPrivatePost: false,
    canPrivateProfile: false,
    canBulkDownload: false,
    canFilter: false,
    canLinkPrivate: false,
}

export const STANDARD_ACCOUNT : AccountAuthorization = {
    accountLevel: AccountLevel.STANDARD,
    displayName: "Prospect",
    canPrivatePost: true,
    canPrivateProfile: true,
    canBulkDownload: true,
    canFilter: false,
    canLinkPrivate: false,
}

export const PREMIUM_ACCOUNT : AccountAuthorization = {
    accountLevel: AccountLevel.PREMIUM,
    displayName: "Professional",
    canPrivatePost: true,
    canPrivateProfile: true,
    canBulkDownload: true,
    canFilter: true,
    canLinkPrivate: true,
}

export const allAccountAuthorizations = [BASIC_ACCOUNT, STANDARD_ACCOUNT, PREMIUM_ACCOUNT];

export const accountAuthorizationsByLevel = {
    [AccountLevel.BASIC]: BASIC_ACCOUNT,
    [AccountLevel.STANDARD]: STANDARD_ACCOUNT,
    [AccountLevel.PREMIUM]: PREMIUM_ACCOUNT,
}

export const defaultAuth = accountAuthorizationsByLevel[AccountLevel.BASIC];