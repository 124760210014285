import { League, Season } from "../types/constants";
import { notesResponse, pbpVideoLineups } from "../types/dto";
import { PBP_USER_PREFIX, apiGet, apiPostJson } from "./utils";

export const upsertUserSkillRating = async (
  isNba: boolean,
  season: string,
  playerId: number,
  rating: { [key: string]: number },
) => {
  const data = { league: isNba ? "nba" : "wnba", season, playerId, rating };
  const response = await apiPostJson(`${PBP_USER_PREFIX}/create/`, data);
  return true;
};

export const upsertView = async (
  userId: number | null,
  league: League,
  pbpVideoId: number,
  viewTime: number,
  incrementView: boolean,
) => {
  if (userId !== null) {
    const data = {
      league,
      pbpVideoId,
      viewTime,
      incrementView,
    };
    const response = await apiPostJson(`${PBP_USER_PREFIX}/view/`, data);
  }
  return true;
};

export const upsertNote = async (
  userId: number | null,
  league: League,
  season: Season,
  gameId: number,
  pbpVideoId: number,
  note: string,
  notePrivacy: string,
) => {
  if (userId !== null) {
    const data = { league, season, gameId, pbpVideoId, note, notePrivacy };
    const response = await apiPostJson(`${PBP_USER_PREFIX}/note/`, data);
  }
  return true;
};

export const updateEditLater = async (
  userId: number | null,
  league: League,
  season: Season,
  gameId: number,
  pbpVideoId: number,
  isEditLater: boolean,
) => {
  if (userId !== null) {
    const data = { league, season, gameId, pbpVideoId, isEditLater };
    const response = await apiPostJson(`${PBP_USER_PREFIX}/editlater/`, data);
  }
  return true;
};

export interface getNotesData {
  gameId?: number | null | undefined;
  userId?: number | null | undefined;
  pbpVideoIds?: number[] | null | undefined;
  orderByTime: boolean;
  offset: number;
  playerId?: number | null | undefined;
  teamId?: number | null | undefined;
}

export const getNotes = async (data: getNotesData) => {
  const response = await apiPostJson(`${PBP_USER_PREFIX}/notes`, data);
  const res: notesResponse = await response.json();
  return res;
};

export const likeNote = async (
  likedAccountUserId: number,
  pbpVideoId: number,
  playlistId: string | null,
) => {
  const data = { likedAccountUserId, pbpVideoId, playlistId };
  const response = await apiPostJson(`${PBP_USER_PREFIX}/likenote/`, data);
  return true;
};

export const unlikeNote = async (
  likedAccountUserId: number,
  pbpVideoId: number,
) => {
  const data = { likedAccountUserId, pbpVideoId };
  const response = await apiPostJson(`${PBP_USER_PREFIX}/unlikenote/`, data);
  return true;
};
