import { SortDirection } from "@mui/material";
import { allPlayerPlayTypes, League, PlayerPlayTypes, Season, SortType } from "../types/constants";
import { pbpVideoLineups } from "../types/dto";
import { PBP_PREFIX, apiGet, apiPostJson } from "./utils";

export const getGameVideo = async (
  league: League,
  season: Season,
  gameIds: number | number[],
) => {
  const gameIdsQs = Array.isArray(gameIds)
    ? gameIds.map((x) => `gameIds=${x}`).join("&")
    : `gameIds=${gameIds}`;
  const response = await apiGet(
    `${PBP_PREFIX}/games?league=${league}&season=${season}&${gameIdsQs}`,
  );
  const pbpVideo: pbpVideoLineups = await response.json();
  return pbpVideo;
};

export const getPlayerVideo = async (
  league: League,
  season: Season,
  playerId: number,
  teamId: number,
  playTypes: string[],
  shotTypes: number[],
  gameIds: number[],
  lineupPlayerIds: number[],
  skip: number,
  sort: SortType
) => {
  const data = {
    league,
    season,
    playerId,
    teamId,
    playTypes,
    gameIds,
    lineupPlayerIds,
    shotTypes,
    skip,
    sort
  }
  const response = await apiPostJson(
    `${PBP_PREFIX}/player`, data,
  );
  const pbpVideo: pbpVideoLineups = await response.json();
  return pbpVideo;
};
