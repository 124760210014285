import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as React from "react";
import { useState } from "react";


import Typography from "@mui/material/Typography";
import { UserCredential } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login, register } from "../api/auth";
import {
  createUserWithEmailPassword,
  getCurrentUser,
  getFirebaseErrorMsg,
  loginUser,
  loginWithGoogle,
  resetPassword,
  sendVerifyEmail
} from "../api/firebase";
import AuthFields from "../components/AuthFields";
import { useAuth } from "../context/auth-context";
import {
  CREATE_USER_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from "../utils/url-utils";

const tiers = [
  {
    title: "Prospect tier",
    subheader: "Public visibility",
    description: [
      "Player reviews and skill ratings",
      "Profile stats",
      "Film study tools",
    ],
    buttonText: "Free",
    buttonVariant: "contained",
    onClick: () => {},
  },
  {
    title: "Qualified tier",
    subheader: "Custom visibility",
    description: [
      "Choose who can see your profile",
      "Aggregate stats of friend's profiles",
      "Contextual film study filters",
    ],
    buttonText: "$5/month",
    buttonVariant: "contained",
    onClick: () => {},
  },
  {
    title: "Associate tier",
    subheader: "Portfolio builder",
    description: [
      "Create custom tagged film clips",
      "Filter tags by member rating",
      "Help embed film in skills & lineup ratings",
    ],
    buttonText: "$10/month",
    buttonVariant: "contained",
    onClick: () => {},
  },
  {
    title: "Partner tier",
    subheader: "AIR PbP Marketplace",
    description: [
      "Monetize your profiles",
      "List your personal libraries",
      "Book consulting hours",
    ],
    buttonText: "Contact Us",
    buttonVariant: "contained",
    onClick: () => {},
  },
];

export enum AccessType {
  LOGIN = "Log In",
  REGISTER = "Register",
  RESET = "Reset",
}

interface userAccessProps {
  accessType: AccessType;
}

export default function UserAccess(props: userAccessProps) {
  const [accessType, setAccessType] = useState<AccessType>(props.accessType);
  const [apiMsg, _setApiMsg] = useState<string | null>(null);
  const [verifyEmailMsg, setVerifyEmailMsg] = useState(false);
  const userAuth = useAuth();
  const setApiMsg = (msg: string | null) => {
    setVerifyEmailMsg(false);
    _setApiMsg(msg);
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { featureFlag } = userAuth;

  const handleIsLogin = (
    event: React.MouseEvent<HTMLElement>,
    newFormType: AccessType | null,
  ) => {
    if (newFormType !== null) {
      setApiMsg(null);
      setAccessType(newFormType);
    }
  };

  const sendVerificationEmail = async () => {
    try {
      await sendVerifyEmail();
      setApiMsg(
        "Registration is almost complete! Crowdscout has emailed you a verification link to complete registration. If you can't find it, please check your spam folder.",
      );
      return "";
    } catch (err) {
      setApiMsg(
        "Error sending verification email: " + getFirebaseErrorMsg(err),
      );
      return "";
    }
  };

  const onRegister = async (
    username: string,
    email: string,
    password: string,
  ) => {
    if (password.length < 6) {
      setApiMsg("Password must be at least six characters");
      return;
    }
    let uid: string;
    const firebaseUser = await getCurrentUser();
    // if user exists we have completed firebase user creation
    // go to user registration
    if (firebaseUser) uid = firebaseUser.uid;
    else {
      try {
        const firebaseRes = await createUserWithEmailPassword(email, password);
        uid = firebaseRes.user.uid;
      } catch (err) {
        setApiMsg(getFirebaseErrorMsg(err));
        return;
      }
    }

    // user registration
    const res = await register(email, username, uid);
    if (res.status === 409)
      setApiMsg("You have already registered, or the username is taken");
    else if (res.status > 399) setApiMsg("Failed to register");
    else {
      if (!firebaseUser?.emailVerified) {
        await sendVerificationEmail();
      }
    }
  };

  // should have already handled whether email is yet verified before calling loginDb
  // loginDb handles: what if userDb record doesn't exist (403)
  // handles: what if server rejects our firebase token (401)
  const loginDb = async () => {
    const res = await login(false);
    // if no userDb record, redirect to user creation
    // if email not verified, open link
    if (res.status === 401) {
      setApiMsg(
        "There was an issue logging in. Please refresh the page and try again.",
      );
    } else if (res.status === 403) {
      // we are assuming this is because the user record doesn't exist
      setApiMsg(
        "You must choose a username to complete registration. Redirecting in five seconds...",
      );
      setTimeout(() => navigate(`${CREATE_USER_PATH}`), 5000);
    } else if (res.status > 399) {
      setApiMsg("An error occurred");
    } else {
      userAuth.refreshAuth();
      // const user = (await res.json()) as userDto;
      // userContext.setUserId(user.userId);
    }
    return res;
  };

  const onLoginEmailPassword = async (
    username: string,
    email: string,
    password: string,
  ) => {
    // login to google firebase
    let firebaseRes: UserCredential;
    try {
      firebaseRes = await loginUser(email, password);
    } catch (err) {
      setApiMsg(
        `${getFirebaseErrorMsg(err)}, or you need to sign in with Google`,
      );
      return;
    }
    if (!firebaseRes.user.emailVerified) {
      setVerifyEmailMsg(true);
      return;
    }
    const loginResult = await loginDb();
    return navigate("/");
  };

  const onLoginWithGoogle = async () => {
    const firebaseRes = await loginWithGoogle();
    const loginResult = await loginDb();
    if (loginResult.ok) return navigate("/");
  };

  const onReset = async (username: string, email: string) => {
    try {
      setApiMsg(
        "If this email is associated with an account, then we will send a link to reset your password",
      );
      await resetPassword(email);
    } catch (err) {
      setApiMsg(`${getFirebaseErrorMsg(err)}`);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            value={accessType}
            exclusive
            onChange={handleIsLogin}
            aria-label="text alignment"
          >
            <ToggleButton value={AccessType.LOGIN} aria-label="Log in">
              {AccessType.LOGIN}
            </ToggleButton>
            {featureFlag && (
              <ToggleButton value={AccessType.REGISTER} aria-label="Register">
                {AccessType.REGISTER}
              </ToggleButton>
            )}
            <ToggleButton value={AccessType.RESET} aria-label="Reset password">
              {AccessType.RESET}
            </ToggleButton>
          </ToggleButtonGroup>
          {accessType === AccessType.LOGIN && (
            <>
              <AuthFields
                authType={accessType}
                showEmail={true}
                showPassword={true}
                apiMsg={apiMsg}
                onSubmit={onLoginEmailPassword}
              />
              <Button
                sx={{
                  marginTop: "10px",
                  backgroundColor: "black",
                  border: "1px solid gray",
                }}
                type="submit"
                fullWidth
                variant="contained"
                onClick={onLoginWithGoogle}
              >
                <div
                  style={{
                    height: "20px",
                    marginRight: "12px",
                    minWidth: "20px",
                    width: "20px",
                  }}
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                Sign in with Google
              </Button>
            </>
          )}
          {verifyEmailMsg && (
            <Grid item xs={12}>
              <Typography variant="body2">
                You must verify your email to complete registration.{" "}
                <a href="#" onClick={() => sendVerificationEmail()}>
                  Click here
                </a>{" "}
                to send verification email.
              </Typography>
            </Grid>
          )}

          {accessType === AccessType.REGISTER && (
            <>
              <AuthFields
                authType={accessType}
                showUsername={true}
                showPassword={true}
                showEmail={true}
                apiMsg={apiMsg}
                onSubmit={onRegister}
              />
              <Typography variant="body2" marginTop="10px">
                {" "}
                By registering, you agree to the&nbsp;
                <a
                  href={`${TERMS_AND_CONDITIONS_PATH}`}
                  target="_blank"
                  style={{ color: "white" }}
                >
                  terms and conditions
                </a>
                &nbsp;and the&nbsp;
                <a
                  href={`${PRIVACY_POLICY_PATH}`}
                  target="_blank"
                  style={{ color: "white" }}
                >
                  privacy policy
                </a>
                .
              </Typography>
            </>
          )}
          {accessType === AccessType.RESET && (
            <AuthFields
              authType={"Reset Password"}
              showEmail={true}
              apiMsg={apiMsg}
              onSubmit={onReset}
            />
          )}
        </Box>
      </Container>
      {accessType === AccessType.REGISTER && (
        <Container component="main" maxWidth="md">
          <Grid container spacing={{ xs: 3, sm: 5 }} alignItems="stretch">
            {/* {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={6}
              md={6}
              alignItems="stretch">
              <Card sx={{ border: '1px solid', borderColor: theme => theme.palette.secondary.light }}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                // sx={{
                //   backgroundColor: (theme) =>
                //     theme.palette.mode === 'light'
                //       ? theme.palette.grey[200]
                //       : theme.palette.grey[700],
                // }}
                />
                <CardContent sx={{ pt: 0, pb: 0 }}>
                  <List sx={{ listStyleType: 'disc', pl: 4, pt: 0 }}>
                    {tier.description.map((line) => (
                      <ListItem
                        alignItems="flex-start"
                        key={line}
                        disableGutters
                        sx={{ display: 'list-item' }}>
                        {line}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))} */}
          </Grid>
        </Container>
      )}
    </>
  );
}
