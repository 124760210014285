import { pbpVideoDto } from "../types/dto";

export enum FilterType {
  Unwatched = "new",
  Lineup = "lineup",
  Quarter = "quarter",
  Time = "time",
  Location = "loc",
  ScoreDiff = "diff",
}

export enum Quarter {
  Q1 = "1Q",
  Q2 = "2Q",
  Q3 = "3Q",
  Q4 = "4Q",
  OT = "OT",
  Clutch = "Clutch",
}
export interface parsedSearchParams {
  [FilterType.Unwatched]: boolean;
  [FilterType.Lineup]: Set<number>;
  [FilterType.Quarter]: Set<string>;
}
export const parseSearchParams = (
  params: URLSearchParams,
): parsedSearchParams => {
  return {
    [FilterType.Unwatched]: params.get(FilterType.Unwatched) !== null,
    [FilterType.Lineup]: new Set(
      (params
        .get(FilterType.Lineup)
        ?.split(",")
        .map((x) => parseInt(x))
        .filter((x) => !isNaN(x)) as number[]) ?? [],
    ),
    [FilterType.Quarter]: new Set(
      params.get(FilterType.Quarter)?.split(",") ?? [],
    ),
  };
};

export const getParamsToSet = (
  key: string,
  val: string | null,
  params: URLSearchParams,
) => {
  val === null ? params.delete(key) : params.set(key, val);
  return params;
};

export const quarters = [
  Quarter.Q1,
  Quarter.Q2,
  Quarter.Q3,
  Quarter.Q4,
  Quarter.OT,
  Quarter.Clutch,
];

export const convertGameClockToSeconds = (gameClock: string) => {
  const colonIndex = gameClock.indexOf(":");
  const minutes = parseInt(gameClock.substring(0, colonIndex));
  const seconds = parseInt(gameClock.substring(colonIndex + 1));
  return convertMinSecToSeconds(minutes, seconds);
};

export const convertMinSecToSeconds = (min: number, sec: number) => {
  return min * 60 + sec;
};

export enum eventMsgType {
  MadeShots = 1,
  MissedShots = 2,
  FreeThrows = 3,
  Rebounds = 4,
  TurnoverSteals = 5,
  Foul = 6,
  Violations = 7,
  Subs = 8,
  Timeouts = 9,
  JumpBall = 10,
  Ejection = 11,
  StartQ = 12,
  EndQ = 13,
  Unknown = 18,
}

export function playIsTurnover(x: pbpVideoDto) {
  return (
    x.eventMsgType === eventMsgType.TurnoverSteals ||
    (x.eventMsgType === eventMsgType.Foul && x.eventMsgActionType === 26)
  );
}

export function playIsMissedShot(x: pbpVideoDto) {
  return x.eventMsgType === eventMsgType.MissedShots;
}

export function playIsMadeShot(x: pbpVideoDto) {
  return x.eventMsgType === eventMsgType.MadeShots;
}

export function playerCommittedTurnover(
  playerId: number | null,
  x: pbpVideoDto,
) {
  if (playerId === null || playerId === undefined) return playIsTurnover(x);
  else {
    return playIsTurnover(x) && x.player1Id === playerId;
  }
}

export function playerForcedTurnover(playerId: number | null, x: pbpVideoDto) {
  let playerForcedTurnover: (x: pbpVideoDto) => boolean;
  if (playerId === null || playerId === undefined)
    playerForcedTurnover = (x) =>
      x.player2Id !== null && x.player2Id !== undefined;
  else playerForcedTurnover = (x) => x.player2Id === playerId;

  return playIsTurnover(x) && playerForcedTurnover(x);
}

export function playerMadeShot(playerId: number, x: pbpVideoDto) {
  return playIsMadeShot(x) && x.player1Id === playerId;
}

export function playerMissedShot(playerId: number, x: pbpVideoDto) {
  return playIsMissedShot(x) && x.player1Id === playerId;
}

export function shotIs2P(x: pbpVideoDto) {
  return !x.is3Pt;
}

export function shotIs3P(x: pbpVideoDto) {
  return x.is3Pt !== null && x.is3Pt !== undefined && x.is3Pt;
}

export function shotIsAssisted(x: pbpVideoDto) {
  return x.player2Id !== null && x.player2Id !== undefined;
}

export function shotIsUnassisted(x: pbpVideoDto) {
  return !x.player2Id;
}

export function playerMadeShotQueryFiltered(
  playerId: number | null,
  isAssisted: boolean,
  is3: boolean,
  shotFilterIds: number[],
  x: pbpVideoDto,
) {
  const is3Exp = is3 ? shotIs3P(x) : shotIs2P(x);
  const assistedExp = isAssisted ? shotIsAssisted(x) : shotIsUnassisted(x);
  const madeShotExp =
    playerId !== null ? playerMadeShot(playerId, x) : playIsMadeShot(x);
  return is3Exp && assistedExp && madeShotExp;
}

export function playerMissedShotQueryFiltered(
  playerId: number | null,
  is3: boolean,
  shotFilterIds: number[],
  x: pbpVideoDto,
) {
  const is3Exp = is3 ? shotIs3P(x) : shotIs2P(x);
  const missedShotExp =
    playerId !== null ? playerMissedShot(playerId, x) : playIsMissedShot(x);
  return is3Exp && missedShotExp;
}

export function playerAssistedShot(playerId: number | null, x: pbpVideoDto) {
  let playerIsAssister;
  if (playerId === null || playerId === undefined)
    playerIsAssister = (x: pbpVideoDto) =>
      x.player2Id !== null && x.player2Id !== undefined;
  else playerIsAssister = (x: pbpVideoDto) => x.player2Id === playerId;

  return playIsMadeShot(x) && playerIsAssister(x);
}

export function playerBlockedShot(playerId: number | null, x: pbpVideoDto) {
  return (
    playIsMissedShot(x) &&
    (!playerId
      ? x.player3Id !== null && x.player3Id !== undefined
      : x.player3Id === playerId)
  );
}

export function playerisOffReb(playerId: number | null, x: pbpVideoDto) {
  return (
    playIsMissedShot(x) &&
    (!playerId || x.rebPlayerId === playerId) &&
    x.isOffReb !== null &&
    x.isOffReb !== undefined &&
    x.isOffReb
  );
}

export function playerIsDefReb(playerId: number | null, x: pbpVideoDto) {
  return (
    playIsMissedShot(x) &&
    (!playerId || x.rebPlayerId === playerId) &&
    x.isOffReb !== null &&
    x.isOffReb !== undefined &&
    !x.isOffReb
  );
}

export function playerIsReb(playerId: number | null, x: pbpVideoDto) {
  return playIsMissedShot(x) && (!playerId || x.rebPlayerId === playerId);
}

export function playerIsNearestDefender(
  playerId: number | null,
  x: pbpVideoDto,
) {
  return x.defPlayerId === playerId;
}

export function playerDefMadeShotQueryFiltered(
  playerId: number | null,
  isAssisted: boolean,
  is3: boolean,
  shotFilterIds: number[],
  x: pbpVideoDto,
) {
  const assistedExp = isAssisted ? shotIsAssisted(x) : shotIsUnassisted(x);
  const is3Exp = is3 ? shotIs3P(x) : shotIs2P(x);
  return (
    assistedExp &&
    is3Exp &&
    playIsMadeShot(x) &&
    (!playerId || playerIsNearestDefender(playerId, x))
  );
}

export function playerDefMissedShotQueryFiltered(
  playerId: number | null,
  is3: boolean,
  shotFilterIds: number[],
  x: pbpVideoDto,
) {
  const is3Exp = is3 ? shotIs3P(x) : shotIs2P(x);
  return (
    is3Exp &&
    playIsMissedShot(x) &&
    (!playerId || playerIsNearestDefender(playerId, x))
  );
}

const foulShoteventMsgActionTypes = [2, 9, 14, 15];

export function playIsFoul(x: pbpVideoDto) {
  return x.eventMsgType === eventMsgType.Foul;
}

export function playIsFoulShots(x: pbpVideoDto) {
  return (
    playIsFoul(x) && foulShoteventMsgActionTypes.includes(x.eventMsgActionType)
  );
}

export function playerDrewRegularFTs(playerId: number, x: pbpVideoDto) {
  // doesn't work, refactor to writing out the expression when you need to
  const playerWasFouled = (x: pbpVideoDto) => x.player2Id === playerId;
  return playIsFoulShots(x) && playerWasFouled(x);
}

export function playerDrewAnd1(playerId: number, x: pbpVideoDto) {
  // doesn't work, refactor to writing out the expression when you need to
  return playerMadeShot(playerId, x) && x.andOne !== null && x.andOne === true;
}

export function playerDrewFTs(playerId: number | null, x: pbpVideoDto) {
  return (
    ((!playerId || x.player2Id === playerId) &&
      x.eventMsgType === eventMsgType.Foul &&
      foulShoteventMsgActionTypes.includes(x.eventMsgActionType)) ||
    (x.andOne &&
      x.andOne &&
      x.eventMsgType === eventMsgType.MadeShots &&
      (!playerId || x.player1Id === playerId))
  );
}

export function playerCommittedFoul(playerId: number | null, x: pbpVideoDto) {
  return playIsFoul(x) && (!playerId || x.player1Id === playerId);
}
