import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDataContext } from "../context/nba-data";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserPlayerViews } from "../api/user";
import { Season } from "../types/constants";
import { playerDto, userPlayerViewsDto } from "../types/dto";
import { sortGame } from "./UserGameProfile";

import { visuallyHidden } from "@mui/utils";
import { TableVirtuoso } from "react-virtuoso";
interface userPlayerProfileProps {
  season: Season;
  userSearch: string;
  userSearchForDisplay: string;
  userId: number;
}

type Order = "asc" | "desc";

const headCells = [
  {
    id: "player",
    label: "Player",
  },
  {
    id: "percent",
    label: "%",
  },
];

function getComparator(
  order: Order,
  orderBy: string,
  playerViews: userPlayerViewsDto,
) {
  return (playerA: playerDto, playerB: playerDto) => {
    let returnVal = 0;
    if (orderBy === "player") {
      if (playerB.name < playerA.name) {
        returnVal = -1;
      } else if (playerB.name > playerA.name) {
        returnVal = 1;
      }
    } else {
      returnVal = sortGame(
        playerViews.userPlayerViews[playerA.playerId] ?? 0,
        playerViews.totalPlayerViews[playerA.playerId] ?? 1,
        playerViews.userPlayerViews[playerB.playerId] ?? 0,
        playerViews.totalPlayerViews[playerB.playerId] ?? 1,
      );
    }
    return order === "desc" ? returnVal : -returnVal;
  };
}

let playerTeams: { [key: string]: Set<number> } = {};
const setPlayerTeams = (allPlayersByTeamId: { [key: string]: playerDto[] }) => {
  playerTeams = {};
  Object.keys(allPlayersByTeamId).forEach((teamId) => {
    allPlayersByTeamId[teamId].forEach((player) => {
      if (playerTeams[player.playerId] === undefined)
        playerTeams[player.playerId] = new Set<number>();
      playerTeams[player.playerId].add(parseInt(teamId));
    });
  });
};

export default function UserPlayerProfile(props: userPlayerProfileProps) {
  const [order, setOrder] = useState("asc" as Order);
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [season, setSeason] = useState(props.season);
  const [playerSearch, setPlayerSearch] = useState("");
  const [playerViews, setPlayerViews] = useState({
    totalPlayerViews: {},
    userPlayerViews: {},
  } as userPlayerViewsDto);
  const data = useDataContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const virtuosoRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const element = virtuosoRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const distanceToViewportTop = rect.top + window.scrollY;
      setDistance(distanceToViewportTop);
    }
  }, []);

  const [userSearch, setUserSearch] = useState(props.userSearch);
  const [userSearchForDisplay, setUserSearchForDisplay] = useState(
    props.userSearchForDisplay,
  );
  useEffect(() => setUserSearch(props.userSearch), [props.userSearch]);
  useEffect(
    () => setUserSearchForDisplay(props.userSearchForDisplay),
    [props.userSearchForDisplay],
  );

  const allPlayersByTeamId = data.seasonTeamPlayers[season];
  useMemo(() => {
    setPlayerTeams(allPlayersByTeamId);
  }, []);
  useEffect(() => {
    getUserPlayerViews(props.userId, props.season).then((res) => {
      setPlayerTeams(data.seasonTeamPlayers[props.season]);
      setSeason(props.season);
      setPlayerViews(res);
    });
  }, [props.userId, props.season]);
  const allGames = data.gamesBySeason[season];
  const teamsById = data.teams;

  const userSearchLower = userSearch.toLowerCase();
  const playerSearchUpper = playerSearch.toLowerCase();
  const visiblePlayers = useMemo(() => {
    const alreadyAddedPlayers = new Set<number>();
    const players: playerDto[] = [];
    Object.keys(allPlayersByTeamId)
      .filter((x) => {
        const team = teamsById[x];
        return (
          userSearch.length === 0 ||
          team.teamCity.toLowerCase().includes(userSearchLower) ||
          team.teamName.toLowerCase().includes(userSearchLower) ||
          team.triCode.toLowerCase().includes(userSearchLower)
        );
      })
      .forEach((teamId) => {
        allPlayersByTeamId[teamId]
          // .filter(p => p.name.toLocaleUpperCase().includes(playerSearchUpper))
          .forEach((p) => {
            if (!alreadyAddedPlayers.has(p.playerId)) {
              alreadyAddedPlayers.add(p.playerId);
              players.push(p);
            }
          });
      });
    players.sort(getComparator(order, orderBy, playerViews));
    return players;
  }, [order, orderBy, playerViews, userSearch]);
  return (
    <>
      <Grid container sx={{ mt: "10px", maxWidth: "500px", margin: "auto" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer ref={virtuosoRef}>
            <TableVirtuoso
              style={{
                height: `calc(100vh - ${distance}px)`,
                margin: "auto",
              }}
              data={useMemo(() => visiblePlayers, [visiblePlayers])}
              fixedHeaderContent={() => (
                <TableRow sx={{ backgroundColor: "#2b2b2b" }}>
                  <TableCell></TableCell>
                  {headCells.map((headCell, i) => (
                    <TableCell
                      sx={{ paddingBottom: "2px", paddingTop: "2px",  textAlign: 'center'  }}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => {
                          const isAsc =
                            orderBy === headCell.id && order === "asc";
                          setOrder(isAsc ? "desc" : "asc");
                          setOrderBy(headCell.id);
                        }}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              )}
              itemContent={(index, player) => {
                const userTotal =
                  playerViews.userPlayerViews[player.playerId] ?? 0;
                const playerTotal =
                  playerViews.totalPlayerViews[player.playerId] ?? 0;
                return (
                  <>
                    <TableCell>
                      <Avatar
                        alt={player.name}
                        sx={{ height: 80, width: 80 }}
                        src={`https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${player.playerId}.png`}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "230px" }}>
                      <Typography variant="body2" marginBottom='5px'>
                        {player.name}
                      </Typography>
                      {Array.from(playerTeams[player.playerId])
                        .map((t) => {
                          return <img
                            src={`https://cdn.nba.com/logos/nba/${teamsById[t].teamId}/primary/L/logo.svg`}
                            height={30}
                          />
                        })
                      }
                    </TableCell>
                    <TableCell sx={{ width: "150px", textAlign: 'center' }}>
                      <Typography variant="body1"  >
                        {playerTotal > 0
                          ? Math.round((userTotal / playerTotal) * 100)
                          : 0}
                        %
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        ({userTotal}/{playerTotal})
                      </Typography>
                    </TableCell>
                  </>
                );
              }}
            />
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}
