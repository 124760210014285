import { Season } from "../types/constants";
import {
  countById,
  gamesBySeasonDto,
  skillsDataDto,
  teamsAndPlayersDto,
  userGameViewsDto,
} from "../types/dto";
import { gamesBySeason } from "../types/ui";
import { CDN_PREFIX, NBA_PREFIX, apiGet } from "./utils";

export const getAllLoadData = async () => {
  const response = await fetch(`${CDN_PREFIX}/load-data.json`);
  const data = (await response.json()) as loadData;
  return data;
};

export interface loadData extends teamsAndPlayersDto {
  games: gamesBySeasonDto;
  skills: skillsDataDto;
  shotTypes: { [key: string]: number[] };
}

export const getAllShotTypes = async () => {
  const response = await apiGet(`${NBA_PREFIX}/shotTypes`);
  const shotTypes = (await response.json()) as { [key: string]: number[] };
  return shotTypes;
};

export const getAllGames = async () => {
  const response = await apiGet(`${NBA_PREFIX}/games`);
  const games = (await response.json()) as gamesBySeasonDto;
  return games;
};

export const getAllTeamsStps = async () => {
  const response = await apiGet(`${NBA_PREFIX}/players`);
  const players = (await response.json()) as teamsAndPlayersDto;
  return players;
};

export interface playerBySeason {
  [key: string]: playerUI[];
}
export interface playerPartial {
  playerId: number;
  name: string;
  teamAbbrs: string[];
  teamNames: string;
}
export interface playerUI {
  playerId: number;
  name: string;
  teamAbbrs: string;
  teamNames: string;
}
export const convertSTPToPlayers = (stp: teamsAndPlayersDto) => {
  const toReturn = {} as playerBySeason;
  Object.keys(stp.seasonTeamPlayers).forEach((season) => {
    const playersByPlayerId = {} as { [key: string]: playerPartial };
    const teamPlayers = stp.seasonTeamPlayers[season];
    Object.keys(teamPlayers).forEach((teamId) => {
      const players = teamPlayers[teamId];
      players.forEach((player) => {
        const abbr = stp.teams[teamId].triCode.toLocaleUpperCase();
        const teamName = stp.teams[teamId].teamName;
        if (playersByPlayerId[player.playerId] === undefined) {
          playersByPlayerId[player.playerId] = {
            ...player,
            teamAbbrs: [abbr],
            teamNames: teamName,
          };
        } else {
          playersByPlayerId[player.playerId].teamAbbrs.push(abbr);
          playersByPlayerId[player.playerId].teamNames += ", " + teamName;
        }
      });
    });
    const fixedPlayers = [] as playerUI[];
    Object.keys(playersByPlayerId).forEach((playerId) => {
      playersByPlayerId[playerId].teamAbbrs = playersByPlayerId[
        playerId
      ].teamAbbrs.sort((a, b) => a.localeCompare(b));
      fixedPlayers.push({
        ...playersByPlayerId[playerId],
        teamAbbrs: playersByPlayerId[playerId].teamAbbrs.join(", "),
      });
    });
    fixedPlayers.sort((a, b) => a.name.localeCompare(b.name));
    toReturn[season] = fixedPlayers;
  });
  return toReturn;
};
export const convertGames = (gamesBySeason: gamesBySeasonDto) => {
  const toReturn = {} as gamesBySeason;
  for (const season in gamesBySeason) {
    toReturn[season] = gamesBySeason[season].map((x) => {
      return {
        ...x,
        homePlayerIds: new Set(x.homePlayerIds),
        awayPlayerIds: new Set(x.awayPlayerIds),
      };
    });
  }
  return toReturn;
};

export const getGameVideoTotals = async (season: Season) => {
  const response = await apiGet(
    `${NBA_PREFIX}/gamevideototals?season=${season}`,
  );
  const games = (await response.json()) as userGameViewsDto;
  return games;
};
