import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getNotes } from "../api/pbp-user";
import ClipView from "../components/ClipView";
import { noteAndPbpDto } from "../types/dto";
import { CLIP_PBP, CLIP_USER } from "../utils/url-utils";

interface clipProps {}

export default function Clip(props: clipProps) {
  const [loaded, setLoaded] = useState(false);
  const [note, setNote] = useState(null as null | noteAndPbpDto);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get(CLIP_USER));
  const pbpVideoId = Number(searchParams.get(CLIP_PBP));
  const pbpVideoIds = [pbpVideoId];
  useEffect(() => {
    getNotes({ userId, pbpVideoIds, orderByTime: true, offset: 0 }).then(
      (res) => {
        setLoaded(true);
        if (res.notes.length > 0) setNote(res.notes[0]);
      },
    );
  }, []);
  let content = null;
  if (!loaded) content = <Typography variant="body2">Loading...</Typography>;
  else if (note === null)
    content = <Typography variant="body2">Post doesn't exist</Typography>;
  else content = <ClipView note={note} pbpVideo={note.pbpVideo} />;
  return content;
}
