import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Comparers, comparers } from "../../types/constants";

interface numCompareSelectorProps {
  comparerLabel: string;
  comparer: Comparers;
  numInputName: string;
  numVal: number;
  onChange: (comparer: Comparers, num: number) => void;
}

export default function NumCompareSelector(props: numCompareSelectorProps) {
  return (
    <div style={{ display: "inline-block" }}>
      <TextField
        select
        label={props.numInputName}
        size="small"
        sx={{ minWidth: "80px" }}
        value={props.comparer}
        color="secondary"
        onChange={(e) =>
          props.onChange(e.target.value as Comparers, props.numVal)
        }
      >
        {comparers.map((comparer) => (
          <MenuItem key={`comparer${comparer.value}`} value={comparer.value}>
            {/* {props.comparerLabel}  */}
            {comparer.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        size="small"
        id="outlined-number"
        color="secondary"
        type="number"
        sx={{ width: "60px" }}
        value={props.numVal}
        onChange={(e) => {
          const num = parseInt(e.target.value);
          if (num >= 0 && num <= 100) props.onChange(props.comparer, num);
          else if (isNaN(num)) props.onChange(props.comparer, 0);
        }}
        InputProps={{ inputProps: { min: 0, max: 100 } }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}
