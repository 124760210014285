import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useState } from "react";
import { useDataContext } from "../context/nba-data";

import { getSortedTeams, searchTeams } from "../utils/game-utils";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Season } from "../types/constants";
import { userGameViewsDto } from "../types/dto";
import { hexToRgb } from "../utils/css-utils";
interface userTeamProfileProps {
  gameViews: userGameViewsDto;
  season: Season;
  userId: number;
  userSearch: string;
  userSearchForDisplay: string;
  onSelectTeam: (teamId: string) => void;
}

const imgSize = 50;
const addTotalHandleNull = (
  key: string,
  val: number,
  dict: { [key: string]: number },
) => {
  const currVal = dict[key];
  dict[key] = dict[key] === undefined ? val : currVal + val;
};
// const completePercent = Math.floor(Math.random() * 100);
export default function UserTeamProfile(props: userTeamProfileProps) {
  const [season, setSeason] = useState(props.season);
  const [gameViews, setGameViews] = useState(props.gameViews);
  const data = useDataContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("userId"));

  const [userSearch, setUserSearch] = useState(props.userSearch);
  const [userSearchForDisplay, setUserSearchForDisplay] = useState(
    props.userSearchForDisplay,
  );
  useEffect(() => setUserSearch(props.userSearch), [props.userSearch]);
  useEffect(
    () => setUserSearchForDisplay(props.userSearchForDisplay),
    [props.userSearchForDisplay],
  );
  useEffect(() => setSeason(props.season), [props.season]);
  useEffect(() => setGameViews(props.gameViews), [props.gameViews]);
  const allPlayers = data.seasonTeamPlayers[season];
  const allGames = data.gamesBySeason[season];
  const teamsById = data.teams;


  const totalsByTeamId = useMemo(() => {
    const user: { [key: string]: number } = {};
    const team: { [key: string]: number } = {};
    allGames.forEach((game) => {
      const userTotal = gameViews.userGameViews[game.gameId] ?? 0;
      const gameTotal = gameViews.totalGameViews[game.gameId] ?? 0;
      addTotalHandleNull(game.homeTeamId, userTotal, user);
      addTotalHandleNull(game.awayTeamId, userTotal, user);
      addTotalHandleNull(game.homeTeamId, gameTotal, team);
      addTotalHandleNull(game.awayTeamId, gameTotal, team);
    });
    return { user, team };
  }, [gameViews]);

  return (
    <>
      <Grid container sx={{ mt: "10px" }}>
        {/* <Grid item xs={12} textAlign="center">
                <FormControl>
                    <TextField id="outlined-basic" label="Filter games" variant="outlined" size="small" color="secondary" 
                     value={gameSearch}
                     placeholder="Search team, score, date"
                     InputLabelProps={{ shrink: true }}
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                       setGameSearch(event.target.value);
                     }}/>
                </FormControl>
            </Grid> */}

        {getSortedTeams(
          userSearch.length < 3
            ? Object.values(teamsById)
            : searchTeams(Object.values(teamsById), userSearch),
        ).map((team) => {
          const rgb = hexToRgb(team.teamColor);
          const rgbPrefix = `rgba(${rgb?.r},${rgb?.g},${rgb?.b}`;
          const backgroundColor = rgb ? `${rgbPrefix},0.3)` : undefined;
          const blackBg = `rgba(255,255,255,0.05)`;
          const progressBg = rgb ? `${rgbPrefix},0.6)` : undefined;
          const completeDec = (totalsByTeamId.user[team.teamId] || 0) / (totalsByTeamId.team[team.teamId] || 1);
          const completePercent = Math.round(completeDec * 100);
          return (
            <>
              {/* <Grid item xs={12} sx={{backgroundColor}}>
                            <div style={{position: 'absolute',  top: 0, left: 0, backgroundColor: progressBg, height: 50, width: '50%'}}></div>
                            <div style={{position: 'absolute', top: 0, left: 0, backgroundColor,  height: 50, width: '100%'}}></div>
                        </Grid> */}
              <div
                style={{
                  height: `${imgSize}px`,
                  width: "100%",
                  position: "relative",
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "inline-block", height: "100%" }}>
                  <img
                    src={`https://cdn.nba.com/logos/nba/${team.teamId}/primary/L/logo.svg`}
                    height={imgSize}
                    style={{ backgroundColor: blackBg }}
                  />
                </div>
                <div
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: `calc((100% - ${imgSize}px) * ${completeDec})`,
                    backgroundColor: progressBg,
                    borderRight: `3px solid rgba(255, 255, 255, 0.15)`,
                  }}
                ></div>
                <div
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: `calc((100% - ${imgSize}px) * ${1 - completeDec})`,
                    backgroundColor: blackBg,
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    left: imgSize + 10,
                    top: 0,
                  }}
                >
                  <Typography variant="body2" lineHeight={`${imgSize}px`}>
                    {team.triCode.toLocaleUpperCase()}
                  </Typography>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    right: 0,
                    top: 0,
                  }}
                >
                  <Typography variant="body2" lineHeight={`${imgSize}px`}>
                    {totalsByTeamId.team[team.teamId] > 0 ? completePercent : 0}
                    % ({totalsByTeamId.user[team.teamId]}/
                    {totalsByTeamId.team[team.teamId]})
                    <IconButton
                      color="secondary"
                      onClick={() => props.onSelectTeam(team.teamName)}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Typography>
                </div>
              </div>
            </>
          );
        })}
      </Grid>

      {
        // selectedPlayer !== null && <Grid container>
        //     {
        //         ratings.map((rating, i) => {
        //             const setRating = (newVal: number | null) => {
        //                 ratings[i].score = newVal;
        //                 setRatings([...ratings]);
        //             }
        //             return (<RatingScore type={rating.name} score={rating.score} setRating={setRating} />);
        //         })
        //     }
        // </Grid>
      }
    </>
  );
}
