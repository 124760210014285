import TextField from "@mui/material/TextField";
import { MAX_POST_SIZE_PX } from "../common/css-constants";

interface addEditNoteProps {
  isPlaylist: boolean;
  userLoggedIn: boolean;
  userIsAuthor: boolean;
  noteVal: string;
  onChange: (noteVal: string) => void;
}
export default function NoteText(props: addEditNoteProps) {
  const disabledClass = { color: "white" };
  return (
    <TextField
      onKeyDown={e => e.stopPropagation()}
      inputProps={{
        maxLength: 3000,
        style: { color: "white", WebkitTextFillColor: "white" },
      }}
      color='secondary'
      disabled={!props.userLoggedIn || !props.userIsAuthor}
      sx={{ maxWidth: MAX_POST_SIZE_PX, width: "100%" }}
      multiline
      size="small"
      minRows={1}
      label={props.isPlaylist ? 'Description' : 'Post'}
      //   maxRows={4}
      placeholder={
        props.userLoggedIn
          ? "Add your notes here..."
          : "Log in to start posting"
      }
      variant="outlined"
      value={props.noteVal}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
      }}
    />
  );
}
